export default {
  // region At A Glance - Dashboard Page
  'component.atAGlance.totalCustomers': 'Registrations',
  'component.atAGlance.totalCustomers.tooltip': 'All leads finishing the questionnaire.',
  'component.atAGlance.totalRequests': 'Requests',
  'component.atAGlance.totalRequests.tooltip': 'All customers requesting an appointment.',
  'component.atAGlance.totalSubscribers': 'Subscribers',
  'component.atAGlance.totalSubscribers.tooltip':
    'All customers without appointment who confirmed the email address for retargeting.',
  'component.atAGlance.sentMails': 'Sent Mails',
  // endregion

  // region Registrations - Dashboard Page
  'component.registrationsChart.day': 'Day',
  'component.registrationsChart.week': 'Week',
  'component.registrationsChart.month': 'Month',
  'component.registrationsChart.year': 'Year',
  'component.registrationsChart.subscribers': 'Subscribers',
  // endregion

  // region ServiceRequest - Dashboard Page
  'component.serviceRequest.cardTitle': 'Last service requests',
  'component.serviceRequest.cardTooltip':
    'Shows current inquiries from customers who have requested an appointment',
  'component.serviceRequest.showAll': 'Show All',
  'component.serviceRequest.scrollMore': 'Scroll down to load more',
  'component.serviceRequest.title': 'Service Request',
  'component.serviceRequest.drawerDefaultLabel': 'Service Request Details',
  'component.serviceRequest.noMoreLoad': 'No More to Load ...',
  // endregion

  // region CustomerDrawer
  'component.CustomerDrawer.qualification': 'Qualifications',
  'component.CustomerDrawer.status': 'Status',
  'component.CustomerDrawer.notes': 'Notes',
  'component.CustomerDrawer.profile': 'Profile',
  'component.CustomerDrawer.styles': 'Styles',
  'component.CustomerDrawer.results': 'Results',
  'component.CustomerDrawer.unsubscribe': 'Unsubscribe',
  'component.CustomerDrawer.showProfile': 'Show Profile',
  // endregion

  // region LocationDrawer - Locations Page
  'component.locationDrawer.availability': 'Availability',
  'component.locationDrawer.addOpeningHours': 'Add recurring opening hours',
  'component.locationDrawer.currentOpeningHours': 'Current recurring opening hours',
  'component.locationDrawer.saturday': 'Saturday',
  'component.locationDrawer.sunday': 'Sunday',
  'component.locationDrawer.monday': 'Monday',
  'component.locationDrawer.tuesday': 'Tuesday',
  'component.locationDrawer.wednesday': 'Wednesday',
  'component.locationDrawer.thursday': 'Thursday',
  'component.locationDrawer.friday': 'Friday',
  'component.locationDrawer.from': 'From',
  'component.locationDrawer.to': 'To',
  'component.locationDrawer.blockHours': 'Block Special Hours',
  'component.locationDrawer.add': 'Add',
  // endregion

  'component.tagSelect.expand': 'Expand',
  'component.tagSelect.collapse': 'Collapse',
  'component.tagSelect.all': 'All',

  // region PeriodSelector
  'component.periodSelector.day': 'Day',
  'component.periodSelector.week': 'Week',
  'component.periodSelector.month': 'Month',
  // endregion

  // region Table
  'component.table.downloadCSV': 'Download CSV',
  'component.table.showing': 'Showing',
  'component.table.of': ' of ',
  'component.table.items': 'items',
  'component.table.page': 'Page',

  // endregion

  // region Account component
  'component.account.profile': 'Profile',
  'component.account.billings': 'Billings',
  'component.account.language': 'Language',
  'component.account.language.select': 'Please Select Your Language:',
  // endregion

  // region Registration Growth
  'component.registrationGrowth.title': 'Registrations',
  'component.registrationGrowth.hint':
    'Number of newly registered users with or without appointment',

  'component.requestGrowth.title': 'Appointments',
  'component.requestGrowth.hint': 'Sum of customers with at least one appointment.',

  'component.subscriberGrowth.title': 'Subscribers',
  'component.subscriberGrowth.hint': 'Sum of currently active subscribers for email retargeting.',

  'component.registrationGrowth.change': 'Change',
  'component.registrationGrowth.cumulative': 'Cumulative',
  // endregion

  // region User Menu Dropdown
  'component.userMenu.settings': 'Settings',
  'component.userMenu.billing': 'Billing',
  'component.userMenu.support': 'Support',
  'component.userMenu.signOut': 'Sign Out',
  // endregion

  // region MApWithAutoComplete

  'component.mapWithAutoComplete.addressPlaceHolder':'Please enter the address',
  'component.mapWithAutoComplete.addressError' :'Address not located. If you want to use location search please use a valid address.',
  // endregion

  // region Account
  'component.settings.visibility': 'Visibility',
   // endregion

  // region Lead Sources
  'components.leadSourceSwitcher.webhook': 'Webhook',
  'components.leadSourceSwitcher.csvFile': 'CSV File',
  'components.leadSourceSwitcher.manual': 'Manual',

  // endregion
};
