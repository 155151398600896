import {request} from "./request";
import * as PATHS from "@/constants/path"
import {URLParamBuilder} from "@/utils/functions";
import {GetCustomersRequest} from "@/typings/api/getCustomers";
import {
  CreateCSVLeadSourceRequest,
  CreateCSVLeadsRequest,
  CreateLeadRequest,
  DeleteLeadSourceRequest,
  FetchAssigneesRequest,
  FetchLeadSourceRequest,
  FetchServiceRequest,
  GetLeadSourceRequest
} from "@/typings/api/lead";
import {GetPerformanceChartDataRequest} from "@/typings/api/getPerformanceChartDataRequest";
import {GetFunnelChartDataRequest} from "@/typings/api/getFunnelChartDataRequest";


// region Fetch Leads
type FetchLeads = {
  params: GetCustomersRequest["parameters"],
  url?: string,
  query?: GetCustomersRequest["query"]
}

export function fetchLeads({
                             params,
                             url,
                             query = {}
                           }: FetchLeads) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_LEADS, {
    queryString: query,
    params
  })
  return request<GetCustomersRequest["response"]>(finalUrl, {
    method: 'GET',
    absoluteURL: url
  });
}


export function fetchCalendarViewLeads({
                             params,
                             url,
                             query = {}
                           }: FetchLeads) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_SERVICE_REQUESTS, {
    queryString: query,
    params
  })
  return request<GetCustomersRequest["response"]>(finalUrl, {
    method: 'GET',
    absoluteURL: url
  });
}


export function fetchSingleLeadDetails({
                             params,
                           }: FetchLeads) {
  return request<GetCustomersRequest["response"]>(URLParamBuilder(PATHS.GET_LEAD, {
    params
  }), {
    method: 'GET'
  });
}

// region Edit Lead
type EditLeadsRequest = {
  params: {
    leadId: string
  },
  url?: string,
  data: {
    [key: string]: any
  }
}

export function rescheduleServiceRequestRequest({
                                   params,
                                   data = {}
                                 }) {
  const url = URLParamBuilder(PATHS.RESCHEDULE_SERVICE_REQUEST, {
    params
  })
  return request(url, {
    method: 'POST',
    data
  });
}

export function editLeadsRequest({
                                   params,
                                   url,
                                   data = {}
                                 }: EditLeadsRequest) {
  const finalUrl = url || URLParamBuilder(PATHS.EDIT_LEAD, {
    params
  })
  return request(finalUrl, {
    method: 'PUT',
    absoluteURL: url,
    data
  });
}

export function fetchLeadTasksList({query}) {
  const url = URLParamBuilder(PATHS.GET_LEAD_TASKS, {
    queryString: query
  })
  return request(url, {
    method: 'GET',
  });
}

export function createLeadTaskRequest({
  data
                                      }) {
  const url = !data.leadId ? URLParamBuilder(PATHS.CREATE_LEAD_TASK)
    : URLParamBuilder(PATHS.CREATE_TASK_FOR_LEAD, {
      params: {
        leadId: data.leadId
      }
    })
  return request(url, {
    method: 'POST',
    data
  });
}

export function editLeadTaskRequest({
  data,
  params
                                      }) {
  const url = URLParamBuilder(PATHS.EDIT_LEAD_TASK, {params})
  return request(url, {
    method: 'PUT',
    data
  });
}

export function deleteLeadTaskRequest({
  params
                                      }) {
  const url = URLParamBuilder(PATHS.EDIT_LEAD_TASK, {params})
  return request(url, {
    method: 'DELETE',
  });
}

export function fetchLeadStatusSettings() {
  const url = URLParamBuilder(PATHS.LEAD_STATUS)
  return request(url, {
    method: 'GET'
  });
}

export function fetchLeadLifeCycleEventsSettings() {
  const url = URLParamBuilder(PATHS.LEAD_LIFE_CYCLE_EVENTS_SETTINGS)
  return request(url, {
    method: 'GET'
  });
}

export function createLeadStatusRequest({
                                        data = {}
                                      }) {
  return request(PATHS.LEAD_STATUS, {
    method: 'POST',
    data
  });
}

export function editLeadStatusRequest({
                                        url,
                                        params,
                                        data = {}
                                      }) {
  const finalUrl = url || URLParamBuilder(PATHS.CHANGE_LEAD_STATUS, {
    params
  })
  return request(finalUrl, {
    method: 'PUT',
    absoluteURL: url,
    data
  });
}

export function reorderLeadStatusRequest({
                                           data = {}
                                         }) {
  return request(PATHS.REORDER_LEAD_STATUS, {
    method: 'POST',
    data
  });
}

export function deleteLeadStatusRequest({
                                          params = {}
                                        }) {
  return request(URLParamBuilder(PATHS.CHANGE_LEAD_STATUS, {
    params
  }), {
    method: 'DELETE'
  });
}

export function addLeadStatusRequest({
                                       url,
                                       data = {}
                                     }) {
  const finalUrl = url || URLParamBuilder(PATHS.LEAD_STATUS)
  return request(finalUrl, {
    method: 'PUT',
    absoluteURL: url,
    data
  });
}

export function fetchPerformanceChartData({
                                            url,
                                            query = {}
                                          }: FetchPerformanceChartData) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_LEAD_MANAGEMENT_PERFORMANCE_CHART_DATA, {
    queryString: query,
  })
  return request<GetPerformanceChartDataRequest["response"]>(finalUrl, {
    method: 'GET',
    absoluteURL: url
  });
}

type FetchPerformanceChartData = {
  url?: string,
  query?: GetPerformanceChartDataRequest["query"]
}

export function fetchAssignees(
  params = {}, query = {}) {
  const finalUrl = URLParamBuilder(PATHS.GET_ALL_USER_API_PATH, {
    params,
    queryString: query
  }, false)
  return request<FetchAssigneesRequest["response"]>(finalUrl, {
    method: 'GET'
  });
}


export function batchAssignRequest(
  {params = {}, data = {}}) {
  const finalUrl = URLParamBuilder(PATHS.BATCH_ASSIGN_LEADS, {
    params,
  }, false)
  return request<FetchAssigneesRequest["response"]>(finalUrl, {
    method: 'PATCH',
    data
  });
}


export function createLeadRequest({data,params}) {
  const url = URLParamBuilder(PATHS.CREATE_LEAD_MANUAL_SOURCE, {
    params
  })
  return request<CreateLeadRequest["response"]>(url, {
    method: 'POST',
    data,
  });
}

export function assignLeadRequest({payload}) {
  return request<CreateLeadRequest["response"]>(PATHS.ASSIGN_LEAD, {
    method: 'POST',
    data: payload,
  });
}

export function archiveLeadRequest({payload}) {
  return request<CreateLeadRequest["response"]>(PATHS.ARCHIVE_LEAD, {
    method: 'POST',
    data: payload,
  });
}

export function postCommentToLeadRequest({data, params}) {
  const url = URLParamBuilder(PATHS.COMMENT_ON_LEAD, {params})
  return request(url, {
    method: 'POST',
    data,
  });
}

export function fetchLeadSources(
  params = {}) {
  const finalUrl = URLParamBuilder(PATHS.FETCH_LEAD_SOURCES, {
    params,
  }, false)
  return request<FetchLeadSourceRequest["response"]>(finalUrl, {
    method: 'GET'
  });
}


export function fetchServices(
  params = {}) {
  const finalUrl = URLParamBuilder(PATHS.FETCH_LEAD_SERVICES, {
    params,
  }, false)
  return request<FetchServiceRequest["response"]>(finalUrl, {
    method: 'GET'
  });
}

export function fetchLeadStatuses(
  params = {}) {
  const finalUrl = URLParamBuilder(PATHS.FETCH_LEAD_STATUSES, {
    params,
  }, false)
  return request<FetchLeadStatusRequest["response"]>(finalUrl, {
    method: 'GET'
  });
}

export function fetchFunnelChartData({
                                       url,
                                       query = {}
                                     }: FetchFunnelChartData) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_LEAD_MANAGEMENT_FUNNEL_CHART_DATA, {
    queryString: query,
  })
  return request<GetPerformanceChartDataRequest["response"]>(finalUrl, {
    method: 'GET',
    absoluteURL: url
  });
}

type FetchFunnelChartData = {
  url?: string,
  query?: GetFunnelChartDataRequest["query"]
}

export function fetchRoiFunnelChartData({
                                          url,
                                          query = {}
                                        }: FetchFunnelChartData) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_LEAD_MANAGEMENT_ROI_FUNNEL_CHART_DATA, {
    queryString: query,
  })
  return request<GetPerformanceChartDataRequest["response"]>(finalUrl, {
    method: 'GET',
    absoluteURL: url
  });
}
export function fetchLeadFields() {
  const url = URLParamBuilder(PATHS.GET_LEAD_FIELDS)
  return request(url, {
    method: 'GET'
  });
}


export function createCSVLeadSourceRequest({data}) {
  const url = PATHS.CREATE_LEAD_SOURCE_CSV
  return request<CreateCSVLeadSourceRequest["response"]>(url, {
    method: 'POST',
    data,
  });
}
export function createCSVLeadsRequest({data}) {
  const url = URLParamBuilder(PATHS.CREATE_LEADS_CSV, {
    params: { sourceId: data?.sourceId }
  })
  return request<CreateCSVLeadsRequest["response"]>(url, {
    method: 'POST',
    data,
  });
}
export function deleteLeadSource({ params }: DeleteLeadSourceRequest["parameters"]) {
  const url = URLParamBuilder(PATHS.DELETE_LEAD_SOURCE_CSV, {
    params: { sourceId: params.sourceId }
  })
  return request<DeleteLeadSourceRequest["response"]>(url, {
    method: 'DELETE'
  });
}
export function getLeadSource({ params }: GetLeadSourceRequest["parameters"]) {
  return request<GetLeadSourceRequest["response"]>(URLParamBuilder(PATHS.GET_LEAD_SOURCE, {
    params: { sourceId: params.sourceId }
  }), {
    method: 'GET'
  });
}
export function updateCSVLeadSourceRequest({data}) {
  const url = URLParamBuilder(PATHS.UPDATE_LEAD_SOURCE_CSV, {
    params: { sourceId: data.id }
  })
  return request<CreateCSVLeadSourceRequest["response"]>(url, {
    method: 'PATCH',
    data,
  });
}
