import * as React from 'react'
import {useState} from 'react'
import {Button, Space, Switch} from "antd";
import Table from "@/components/Table";
import type {ProColumns} from "@ant-design/pro-table";
import {FormattedMessage as F, useDispatch, useIntl} from "umi";
import PrettyDate from "@/components/PrettyDate";
import {CheckOutlined} from "@ant-design/icons";
import CreateTaskModal from "@/pages/Tasks/CreateTaskModal";
import {editLeadTaskRequest} from "@/services/api/lead";
import LeadCard from "@/components/Kanban/LeadCard";

export function TasksTable({data, request,loading,leadId, filterColumns = (column) => true, hideCreate, hideShowDone}) {
  const [showDone, setShowDone] = useState(false)
  const dispatch = useDispatch()
  const [localLoading, setLocalLoading] = useState(false)
  const toggleShowDone = () => setShowDone(prev => !prev)
  function doTask(task) {
    setLocalLoading(true)
    editLeadTaskRequest({
      params: {
        taskId: task.id
      },
      data: {
        ...task,
        due_date: task.due_date.replace(".000000Z", "Z"),
        is_completed: true
      }
    }).then(() => {
      setLocalLoading(false)
      request()
    })
  }
  const intl = useIntl()
  const columns: ProColumns<any>[] = [
    {
      title: <F id="pages.lead.tasks.taskTitle" defaultMessage="Title" />,
      dataIndex: 'title',
    },
    {
      title: <F id="pages.lead.tasks.taskDescription" defaultMessage="Description" />,
      dataIndex: 'description_summary',
    },
    {
      title: <F id="pages.lead.tasks.taskDue" defaultMessage="Due date" />,
      dataIndex: 'due_date',
      render: date => <PrettyDate date={date}/>
    },
    {
      title: <F id="pages.lead.tasks.lead" defaultMessage="Lead" />,
      dataIndex: 'lead',
      width: 200,
      render: (lead) => lead &&  lead?.customer && <LeadCard leadContextMenuProps={{
        hideTasksBadge: true
      }} overrideVisibleProperties={['name']} disableDrag hideAssignee lead={lead} id={lead.id}/>
    },
    {
      title: <F id="pages.locations.actions" defaultMessage="Actions" />,
      dataIndex: '',
      render: (_, record) => (
        record.is_completed ? (
          <Button size={"small"} type="default" disabled>
            <F id="pages.lead.tasks.done" defaultMessage="Done" />
          </Button>
        ) : (
          <Button icon={<CheckOutlined/>} type="primary" size={"small"} onClick={() => doTask(record)}/>

        )
      ),
    },
  ].filter(filterColumns);

  return <Table headerTitle={intl.formatMessage({
    id: 'pages.lead.tasks',
    defaultMessage: 'TASKS',
  })} columns={columns}
                size={'small'}
                pagination={false}
                isCard={false}
                search={false}
                request={request}
                fullScreen={false}
                loading={loading || localLoading}
                dataSource={data.filter(task => showDone ? task.is_completed : !task.is_completed)}
                toolBarRender={() =>
                  <Space>
                    {!hideCreate && <CreateTaskModal leadId={leadId} updateRequest={request}/>}
                    {!hideShowDone && <Switch checked={showDone} onChange={toggleShowDone} checkedChildren={<F id="pages.lead.tasks.showOpen" defaultMessage="Show Open" />} unCheckedChildren={<F id="pages.lead.tasks.showDone" defaultMessage="Show Done" />}/>}

                  </Space>
                }
  />
}
