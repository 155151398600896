import { message } from "antd";

export const detectDelimiter = (csvData) => {
  const firstLine = csvData.split('\n')[0];
  const delimiters = [',', ';', '\t', '|'];
  const delimiterCounts = delimiters.map((delimiter) => ({
    delimiter,
    count: (firstLine.match(new RegExp(`\\${delimiter}`, 'g')) || []).length,
  }));
  delimiterCounts.sort((a, b) => b.count - a.count);
  return delimiterCounts[0].delimiter;
};

export const convertFlatToNested = (flatObject) => {
  const nestedObject = {};

  Object.keys(flatObject).forEach((key) => {
    const keys = key.split('.');
    keys.reduce((acc, part, index) => {
      if (index === keys.length - 1) {
        acc[part] = flatObject[key];
      } else {
        acc[part] = acc[part] || {};
      }
      return acc[part];
    }, nestedObject);
  });

  return nestedObject;
};
export const buildDataBasedOnMappings = async (values, customFields,parsedData) => {
    try {
      const mapping = values; 

      const newMappedData = parsedData.map((row) => {
        const mappedRow = {};
        for (const [systemField, csvHeaders] of Object.entries(mapping)) {
          mappedRow[systemField] = csvHeaders?.map(header => row[header]).join(", ");
        }
        mappedRow.meta = {};
        for (const [customField, csvHeaders] of Object.entries(mapping)) {
          if(customField.includes("meta."))
            mappedRow.meta[customField.split("meta.")[1]] = csvHeaders?.map(header => row[header]).join(", ");
        }
        return mappedRow;
      });
      return newMappedData;
    } catch (error) {

      console.error("Error during mapping:", error);
      message.error("Failed to map CSV data.");
    }
  };
  export const buildNestedDataBasedOnMappings = async (values, customFields,parsedData) => {
    try {
      const mapping = values; 

      const newMappedData = parsedData.map((row) => {
        const mappedRow = {};
        for (const [systemField, csvHeaders] of Object.entries(mapping)) {
          mappedRow[systemField] = csvHeaders?.map(header => row[header]).join(", ");
        }
        mappedRow.meta = {};
        for (const [customField, csvHeaders] of Object.entries(mapping)) {
          if(customField.includes("meta."))
            mappedRow.meta[customField.split("meta.")[1]] = csvHeaders?.map(header => row[header]).join(", ");
        }
        return convertFlatToNested(mappedRow);
      });
      return newMappedData;
    } catch (error) {
      console.error("Error during mapping:", error);
      message.error("Failed to map CSV data.");
    }
  };