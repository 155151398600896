import { transformObjectToLocaleMap } from '@/utils/intl';

export default {
  'pages.locations.branchStatus': 'Statut de la succursale',
  'pages.users.createUserFailed': "Echec de la création de l'utilisateur",
  'pages.users.createUserSuccess': 'Utilisateur créé avec succès',
  'pages.users.phone.invalidFormat': 'Veuillez saisir un numéro de téléphone valide',
  'pages.users.removeUserFailed': "Echec de la suppression de l'utilisateur",
  'pages.users.removeUserSuccess': 'Utilisateur supprimé avec succès',
  'pages.users.role': 'Rôle',
  'app.footer.imprint': 'Mentions légales',
  'app.footer.policy': 'Directive',
  'pages.BillingDetails': 'Détails de facturation',
  'pages.ForbiddenPage.message': "Vous n'avez pas accès à cette page",
  'pages.billings.alertMessage': 'Ceci est la page de facturation',
  'pages.billings.created': 'Créé',
  'pages.billings.customer': 'Client',
  'pages.billings.discount': 'Réduction',
  'pages.billings.email': 'E-mail',
  'pages.billings.event': 'Évènement',
  'pages.billings.header': 'Facturation',
  'pages.billings.journey': 'Parcours',
  'pages.billings.mode': 'Mode',
  'pages.billings.period': 'Période',
  'pages.billings.questionnaire': 'Questionnaire',
  'pages.billings.subtotal': 'Sous-total',
  'pages.billings.total': 'Total',
  'pages.common.actions': 'Actes',
  'pages.common.add': 'Ajouter',
  'pages.common.cancel': 'Annuler',
  'pages.common.clear': 'Déselectionner',
  'pages.common.clearAll': 'Tout supprimer',
  'pages.common.confirm': 'Confirmer',
  'pages.common.discard': 'Jeter',
  'pages.common.edit': 'Éditer',
  'pages.common.no': 'non',
  'pages.common.ok': 'OK',
  'pages.common.remove': 'Rejeter',
  'pages.common.save': 'Sauvegarder',
  'pages.common.selected': 'Sélectionné',
  'pages.common.tutorial': 'Tutoriel',
  'pages.common.yes': 'oui',
  'pages.cta.conversions.help':
    'utilisateurs ayant complété le questionnaire avec et sans rendez-vous',
  'pages.cta.page': 'Page',
  'pages.cta.performance': 'Click / Convertir',
  'pages.customer.noUploadedFiles': 'Aucun Fichier Téléchargé',
  'pages.customer.notes': 'Notes',
  'pages.customer.orderVolume': 'Volume de commande',
  'pages.customer.qualification': 'Qualification',
  'pages.customer.results': 'Résultats',
  'pages.customer.state': 'Statut',
  'pages.customer.unsubscribe': 'Désabonner',
  'pages.customer.uploadedFiles': 'Fichiers Téléchargés',
  'pages.customers.actions': 'Actions',
  'pages.customers.actions.download': 'Télécharger CSV',
  'pages.customers.activeRecipient': 'Destinataire actif',
  'pages.customers.avtiveRecipient': 'Abonné',
  'pages.customers.createDate': 'Date de création',
  'pages.customers.created': 'Créé le',
  'pages.customers.details': 'Détails',
  'pages.customers.doubleOptin': 'Double opt-in',
  'pages.customers.email': 'E-mail',
  'pages.customers.engagementScore': "Score d'Engagement",
  'pages.customers.firstName': 'Prénom',
  'pages.customers.gender': 'Sexe',
  'pages.customers.header': 'Clients',
  'pages.customers.lastName': 'Nom',
  'pages.customers.name': 'Nom',
  'pages.customers.phone': 'Téléphone',
  'pages.customers.profile': 'Profile',
  'pages.customers.qualification': 'Qualification',
  'pages.customers.scoreExplanation':
    'Probabilité estimée que ce client achète dans votre magasin en fonction de toutes les interactions précédentes.',
  'pages.customers.status': 'Statut',
  'pages.customers.styles': 'Styles',
  'pages.customers.tableTitle': 'Clients',
  'pages.customers.utmMedium': 'Moyen de contact',
  'pages.dashboard.header': 'Dashboard',
  'pages.forgotPassword.errorMessage':
    "Il y a une erreur avec votre adresse e-mail, veuillez contacter le service d'assistance via la page de contact",
  'pages.forgotPassword.forgotPasswordTitle': 'Réinitialiser le mot de passe',
  'pages.forgotPassword.guidText':
    'À quelle adresse e-mail souhaitez-vous envoyer les informations de réinitialisation du mot de passe?',
  'pages.forgotPassword.hint':
    'À quelle adresse e-mail souhaitez-vous envoyer les informations de réinitialisation du mot de passe?',
  'pages.forgotPassword.invalid':
    "L'adresse e-mail n'est pas enregistrée, veuillez corriger l'adresse.",
  'pages.forgotPassword.label': 'Adresse e-mail',
  'pages.forgotPassword.login': 'Retour à la connexion',
  'pages.forgotPassword.placeholder': 'Adresse e-mail',
  'pages.forgotPassword.required':
    'Ce champ est obligatoire, veuillez saisir votre adresse e-mail.',
  'pages.forgotPassword.sentMail':
    "L'e-mail de réinitialisation du mot de passe a bien été envoyé.",
  'pages.forgotPassword.sentMailSubtitle': 'Veuillez également vérifier votre spam.',
  'pages.forgotPassword.submit': 'Envoyer la demande',
  'pages.forgotPassword.tooltip':
    "Si vous ne vous souvenez pas de votre adresse e-mail, veuillez nous contacter à l'adresse support@conversional.de",
  'pages.forgotpassword.required':
    'Ce champ est obligatoire, veuillez saisir votre adresse e-mail.',
  'pages.images.chooseGroup': 'Sélectionner un groupe pour ce tag.',
  'pages.images.contactSupportToSetUpImageGallery':
    "Vous n'utilisez pas actuellement de galerie d'images personnalisée. Si vous souhaitez avoir plus de contrôle sur vos propres images, n'hésitez pas à nous contacter.",
  'pages.images.createTag': 'Créer un tag',
  'pages.images.createTagGroup': 'Créer un Groupe de tag',
  'pages.images.deleteSelectedImagesConfirm': 'Voulez-vous supprimer (x) images ?',
  'pages.images.deleteSelectedImagesSuccess': 'Images supprimées avec succès',
  'pages.images.directory': 'Dossier',
  'pages.images.editTag': 'éditer tag',
  'pages.images.file': 'Fichier',
  'pages.images.newTag': 'Nouveau tag',
  'pages.images.noCustomImageGalleryAvailable':
    "Vous n'avez pas encore de galerie d'images personnalisée.",
  'pages.images.remove': 'Supprimer',
  'pages.images.removeImageConfirmation':
    'Voulez-vous vraiment supprimer cette image? Cette action est irréversible.',
  'pages.images.removeTagGloballySubTitle': 'Le tag sera supprimé de toutes les images.',
  'pages.images.removeTagGloballyTitle': 'Voulez-vous vraiment supprimer ce tag?',
  'pages.images.requestAccess': 'Demande de galerie personnalisée',
  'pages.images.selectTagStatistics': 'Statistiques',
  'pages.images.selectToBulkEdit': 'Sélectionnez les images que vous souhaitez taguer en bloc',
  'pages.images.tagGroups': 'Groupe de tag',
  'pages.images.tags': 'Tags',
  'pages.images.upload': 'Télécharger',
  'pages.images.uploadDraggerHint': "Télécharger l'image avec la plus haute résolution",
  'pages.images.uploadDraggerText':
    'Pour télécharger, cliquer ou faire glisser des fichiers ou des dossiers sur cette zone.',
  'pages.images.uploadDrawerTitle': "Télécharger l'image",
  'pages.language.english': 'Anglais',
  'pages.language.french': 'Français',
  'pages.language.german': 'Allemand',
  'pages.language.title': 'Choisissez votre langue',
  'pages.layouts.userLayout.title': 'Démarrez votre entretien de vente de cuisine en ligne',
  'pages.leads': 'Leads',
  'pages.leads.CSVExport': 'Export CSV',
  'pages.leads.Service': 'Service',
  'pages.leads.addLead': 'Nouveaux lead',
  'pages.leads.addLeads': 'Nouveaux leads',
  'pages.leads.appointment': 'Rendez-vous',
  'pages.leads.assignLeads': 'Attribuer leads',
  'pages.leads.assignee': 'Assigné',
  'pages.leads.branch': 'Branche',
  'pages.leads.by': 'par',
  'pages.leads.conversionRate': 'Taux de conversion',
  'pages.leads.deals': 'Offres',
  'pages.leads.filters': 'Filtre',
  'pages.leads.filters.assignee.placeholder': 'Vendeur',
  'pages.leads.filters.leadSource.placeholder': 'Source de lead',
  'pages.leads.filters.location.placeholder': 'Filiale',
  'pages.leads.groupBy.label': 'Sélectionner colonnes',
  'pages.leads.lead_state': 'Statut du lead',
  'pages.leads.location': 'Emplacement',
  'pages.leads.marketingChannel': 'Canal de marketing',
  'pages.leads.metrics': 'Métriques',
  'pages.leads.revenue': 'Revenu',
  'pages.leadsources.assignedTo': 'Assigner à',
  'pages.leadsources.branch': 'Succursale',
  'pages.leadsources.configureWebhookStep': 'Configurer le Webhook',
  'pages.leadsources.name': 'Nom',
  'pages.leadsources.priority': 'Priorité',
  'pages.leadsources.removeConfirm': 'Êtes-vous sûr de vouloir supprimer cette source de leads ?',
  'pages.leadsources.reviewMappingAlert': 'Veuillez vérifier vos mappages avant de continuer',
  'pages.leadsources.sepratorProblem': 'Y a-t-il un problème avec le séparateur CSV ?',
  'pages.leadsources.testAndMapStep': 'Tester et mapper le Webhook',
  'pages.location.address': 'Adresse',
  'pages.location.createDate': 'Date de création',
  'pages.location.details': 'des détails',
  'pages.location.edit': 'Modifier les données',
  'pages.location.home': 'À domicile',
  'pages.location.name': 'Nom',
  'pages.location.online': 'En ligne',
  'pages.location.removeBlockPeriod': "Voulez-vous supprimer ces heures d'indisponibilité?",
  'pages.location.removeOpenPeriod':
    "Êtes-vous sûr de vouloir supprimer ces heures d'ouverture supplémentaires?",
  'pages.location.removeRecurringTime': 'Voulez-vous supprimer ces horaires récurrentes?',
  'pages.location.resource.email': 'E-mail',
  'pages.location.resource.name': 'Nom',
  'pages.location.store': 'Magasin',
  'pages.location.type': 'Type',
  'pages.location.type_tooltip': 'Le type de lieu définit le type de rendez-vous pris.',
  'pages.location.videoCall': 'Appel vidéo',
  'pages.location.videoCallDisabled': 'Inactif',
  'pages.location.videoCallEnabled': 'Actif',
  'pages.locations.actions': 'Actions',
  'pages.locations.addBranch': 'Ajouter une succursale',
  'pages.locations.addNewAgent': 'Ajouter un nouvel agent',
  'pages.locations.addNewResource': 'Ajouter un nouveau destinataire',
  'pages.locations.addNewResourceFailed': "La ressource n'a pas pu être ajoutée.",
  'pages.locations.addNewResourceSuccess': 'Nouvelle ressource ajoutée.',
  'pages.locations.additionalOpenHours': "Heures d'ouverture supplémentaires",
  'pages.locations.address': 'Adresse',
  'pages.locations.agents': 'Agents',
  'pages.locations.agentsRequired': 'Veuillez sélectionner au moins un agent !',
  'pages.locations.alertMessage': 'Ceci est la page des sites',
  'pages.locations.appointment_available_to': "Rendez-vous disponibles jusqu'à",
  'pages.locations.appointment_available_to_placeholder': '90 jours',
  'pages.locations.appointment_available_to_tooltip':
    "Nombre maximum de jours à l'avance qu'un rendez-vous peut être réservé",
  'pages.locations.appointment_buffer': 'Intervalle entre les rendez-vous en minutes',
  'pages.locations.appointment_buffer_placeholder': '0 minutes',
  'pages.locations.appointment_buffer_tooltip': 'Temps libre entre les rendez-vous en minutes',
  'pages.locations.appointment_duration': 'Réservation la plus tôt dans',
  'pages.locations.appointment_duration_placeholder': '48 heures',
  'pages.locations.appointment_duration_tooltip':
    "Temps en heures jusqu'à ce que le premier rendez-vous puisse être réservé",
  'pages.locations.appointments': 'Rendez-vous',
  'pages.locations.atLeastOneResource': '**** Each location requires at least one resource.',
  'pages.locations.availability': "Heures d'ouverture",
  'pages.locations.blockHours': "Heures d'indisponibilité",
  'pages.locations.createService': 'Créer un service',
  'pages.locations.created': 'Date de création',
  'pages.locations.currentRecurring': 'Heure récurrente actuelle',
  'pages.locations.days': 'jours',
  'pages.locations.deleteBranchMessage': 'Êtes-vous sûr de vouloir supprimer cette succursale ?',
  'pages.locations.deleteService': 'Supprimer le service',
  'pages.locations.deleteServiceMessage': 'Êtes-vous sûr de vouloir supprimer ce service ?',
  'pages.locations.details': 'Détails',
  'pages.locations.download': 'Télécharger CSV',
  'pages.locations.from': 'Du',
  'pages.locations.fromHour': 'De',
  'pages.locations.header': 'Sites',
  'pages.locations.hours': 'heures',
  'pages.locations.location': 'Magasin',
  'pages.locations.meta': 'Autres',
  'pages.locations.minutes': 'minutes',
  'pages.locations.name': 'Nom',
  'pages.locations.removeLocationConfirm': 'Êtes-vous sûrs de vouloir supprimer ce lieu ?',
  'pages.locations.removeLocationFailed': 'La suppréssion du lieu a échoué',
  'pages.locations.removeLocationSuccess': 'Lieu supprimé avec succès',
  'pages.locations.removeResourceFailed': "La ressource n'a pas pu être supprimée.",
  'pages.locations.removeResourceSuccess': 'Ressource supprimée.',
  'pages.locations.resourceCountLimitation':
    '**** You cannot add more than 5 resources to a location. If you need more resources please contact support.',
  'pages.locations.resources': 'Destinataires',
  'pages.locations.services': 'Services',
  'pages.locations.timeslot_increment': 'Incréments de créneaux horaires',
  'pages.locations.timeslot_increment_placeholder': '60 minutes',
  'pages.locations.timeslot_increment_tooltip':
    'Fréquence à laquelle les créneaux horaires libres sont suggérés en minutes',
  'pages.locations.to': 'Au',
  'pages.locations.toHour': 'à',
  'pages.locations.videoCall': 'Appel vidéo',
  'pages.login.accountLogin.errorMessage': "Nom d'utilisateur/ mot de passe invalide",
  'pages.login.dontHaveAccount': 'Pas encore de compte?',
  'pages.login.dontHaveAnAccount': 'Pas de compte?',
  'pages.login.failure': 'La connexion a échoué, veuillez réessayer.',
  'pages.login.forgotPassword': 'Mot de passe oublié?',
  'pages.login.loginTitle': 'Bienvenue! Veuillez vous connecter',
  'pages.login.password.placeholder': 'Mot de passe',
  'pages.login.password.required': 'Veuillez saisir votre mot de passe.',
  'pages.login.rememberMe': 'Se souvenir de moi',
  'pages.login.requestDemo': 'Demander une démo',
  'pages.login.submit': 'Connexion',
  'pages.login.success': 'Connexion réussie!',
  'pages.login.username.placeholder': 'Adresse e-mail',
  'pages.login.username.required': "Veuillez saisir votre nom d'utilisateur",
  'pages.login.welcome': 'Bienvenue! Se connecter',
  'pages.marketing.header': 'Marketing',
  'pages.marketing.noChart': 'Aucun graphique disponible. Changez le filtre de date.',
  'pages.performance.alertMessage': 'Ceci est la page de performance',
  'pages.performance.cardTitle': 'Entonnoir de conversion',
  'pages.performance.explanation':
    'Les chemins d\'accès sont démarrés à partir du questionnaire. Leurs valeurs "démarrées" se rapportent au chemin, pas aux démarrages des outils. Par conséquent, les démarrages de tous les chemins sont inférieurs aux démarrages des outils lorsque les utilisateurs abandonnent à la première étape.',
  'pages.performance.header': 'Performance',
  'pages.performance.infoText':
    "Ces données sont basées sur les événements de suivi. En raison des bloqueurs de suivi et de l'échantillonnage, ces données ne représentent pas tous les utilisateurs. Il se peut que les valeurs affichées soient différentes de celles du tableau de bord.",
  'pages.performance.inspirationPath': 'Parcours Inspiration',
  'pages.performance.instantAppointmentPath': 'Parcours Rendez-vous',
  'pages.performance.paths': "Chemin d'accès",
  'pages.profile.address': 'Adresse',
  'pages.profile.email': 'E-mail',
  'pages.profile.firstName': 'Prénom',
  'pages.profile.lastName': 'Nom',
  'pages.profile.phone': 'Téléphone',
  'pages.profile.saveChanges': 'Sauvegarder les changements',
  'pages.promotion.selectPromotionConditionPlaceholder': 'Veuillez sélectionner',
  'pages.promotions.addPromotion': 'Ajouter',
  'pages.promotions.addPromotionCondition': 'Ajouter des conditions générales',
  'pages.promotions.dates': 'Durée de validité',
  'pages.promotions.editPromotion': 'Traiter la promotion',
  'pages.promotions.end': 'Fin',
  'pages.promotions.form': 'Formulaire de promotion',
  'pages.promotions.help_1': "Qu'est-ce qu'une promotion ?",
  'pages.promotions.help_2':
    "Vous pouvez ajouter automatiquement des activités promotionnelles à vos courriels. Il vous suffit de créer et d'activer une promotion pour qu'elle soit automatiquement annoncée à vos clients pendant la période donnée.",
  'pages.promotions.invalid_error': 'Veuillez saisir une URL valide',
  'pages.promotions.modifiedDate': 'Dernière modification',
  'pages.promotions.newPromotion': 'Nouvelle promotion',
  'pages.promotions.preview': 'Aperçu',
  'pages.promotions.promotionCondition': "Conditions d'utilisation",
  'pages.promotions.removePromotionConfirm': 'Êtes-vous sûr de vouloir supprimer cette promotion ?',
  'pages.promotions.removePromotionsFailed': "La promotion n'a pas pu être supprimée",
  'pages.promotions.removePromotionsSuccess': 'Promotion supprimée',
  'pages.promotions.showVideoTutorial': 'Voir le tutoriel vidéo',
  'pages.promotions.start': 'Lancement',
  'pages.promotions.tableTitle': 'Promotions',
  'pages.promotions.title': 'Titre',
  'pages.promotions.type': 'Type de promotion',
  'pages.requests.actions': 'Actions',
  'pages.requests.appointmentRequest': 'Date de rendez-vous',
  'pages.requests.customer': 'Client',
  'pages.requests.dateOfRequest': 'Date de création',
  'pages.requests.details': 'Détails',
  'pages.requests.header': 'Clients',
  'pages.requests.tableTitle': 'Rendez-vous',
  'pages.requests.what': 'Quoi',
  'pages.requests.where': 'Où',
  'pages.resetPassword.backToLogin': 'Retour à la page de connexion',
  'pages.resetPassword.errorMessage':
    'Votre mot de passe doit comporter au moins 8 caractères, une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
  'pages.resetPassword.passwordRequired': 'Veuillez saisir votre mot de passe',
  'pages.resetPassword.placeholder': 'Mot de passe',
  'pages.resetPassword.placeholderConfirm': 'Confirmez le mot de passe',
  'pages.resetPassword.setNewPassword': 'Définir un nouveau mot de passe',
  'pages.resetPassword.subTitle': 'Veuillez saisir un nouveau mot de passe',
  'pages.resetPassword.title': 'Veuillez définir un nouveau mot de passe',
  'pages.resetPassword.wrongConfirmation':
    'Les deux mots de passe que vous avez saisis ne correspondent pas.',
  'pages.serviceRequest.customer': 'Client',
  'pages.serviceRequest.inquiry': 'Demande',
  'pages.serviceRequest.qualification': 'Qualification',
  'pages.serviceRequest.what': 'Quoi',
  'pages.serviceRequest.when': 'Quand',
  'pages.serviceRequest.where': 'Où',
  'pages.settings.address.label': 'Adresse',
  'pages.settings.address.placeholder': 'Adresse',
  'pages.settings.address.required': 'Ce champ est obligatoire, veuillez saisir votre adresse',
  'pages.settings.billingTab': 'Facturation',
  'pages.settings.configuration.editSuccessMessage': 'Paramètres mis à jour avec succès !',
  'pages.settings.email': 'E-mail',
  'pages.settings.email.errorMessage':
    'Il y a une erreur avec votre adresse e-mail, veuillez saisir un format valide',
  'pages.settings.email.label': 'Adresse e-mail',
  'pages.settings.email.placeholder': 'Adresse e-mail',
  'pages.settings.email.required': 'Ce champ est obligatoire, veuillez saisir votre adresse e-mail',
  'pages.settings.firstName.errorMessage':
    'Votre prénom ne peut contenir ni chiffres, ni symboles. Veuillez corriger votre prénom',
  'pages.settings.firstName.label': 'Prénom',
  'pages.settings.firstName.placeholder': 'Prénom',
  'pages.settings.firstName.required': 'Ce champ est obligatoire, veuillez saisir votre prénom',
  'pages.settings.header': 'Paramètres du compte',
  'pages.settings.languageTab': 'Langue',
  'pages.settings.lastName.errorMessage':
    'Votre prénom ne peut contenir ni chiffres, ni symboles. Veuillez corriger votre nom de famille',
  'pages.settings.lastName.label': 'Nom de famille',
  'pages.settings.lastName.placeholder': 'Nom de famille',
  'pages.settings.lastName.required':
    'Ce champ est obligatoire, veuillez saisir votre nom de famille',
  'pages.settings.phone.errorMessage':
    'Votre numéro de téléphone ne peut pas contenir de lettre, veuillez corriger le numéro',
  'pages.settings.phone.label': 'Téléphone',
  'pages.settings.phone.placeholder': 'Téléphone',
  'pages.settings.phone.required': 'Ce champ est obligatoire, veuillez saisir votre téléphone',
  'pages.settings.profileTab': 'Profile',
  'pages.settings.saveSettings': 'Enregistrer les changements',
  'pages.settings.telephone': 'Téléphone',
  'pages.settings.visibility.active': 'Actif',
  'pages.settings.visibility.active.tooltip':
    "Vous pouvez activer/ désactiver l'outil sur l'ensemble des pages",
  'pages.settings.visibility.blackList': 'Exclure les pages',
  'pages.settings.visibility.blackList.tooltip':
    "L'outil sera affiché sur toutes les pages sauf celles listées ci-dessous",
  'pages.settings.visibility.clearRules':
    "Veuillez supprimer l'entité des règles avant de changer le type de paramétrage.",
  'pages.settings.visibility.whiteList': 'Afficher seulement sur',
  'pages.settings.visibility.whiteList.tooltip':
    "L'outil sera seulement affiché sur les pages listées ci-dessous",
  'pages.stats.frequency': 'Fréquence',
  'pages.stats.selectTagGroups':
    "Sélectionnez jusqu'à 3 groupes de balises que vous aimeriez comparer",
  'pages.stats.tagCombination': 'Combinaison de balises',
  'pages.support.email': 'Par e-mail',
  'pages.support.header': 'Assistance',
  'pages.support.telephone': 'Par téléphone',
  'pages.support.title': 'Contactez notre assistance technique',
  'pages.users.addUser': 'Ajouter un utilisateur',
  'pages.users.close': 'Fermer',
  'pages.users.email': 'E-mail',
  'pages.users.newUser': 'Nouvel utilisateur',
  'pages.users.phone': 'Téléphone',
  'pages.users.phone.errorMessage':
    'Votre numéro de téléphone ne peut pas contenir de lettres, veuillez corriger le',
  'pages.users.removeUserConfirmation': "Êtes-vous sûr de vouloir supprimer l'utilisateur?",
  'pages.users.selectPlaceholder': 'Veuillez sélectionner un rôle',
  'pages.users.tableTitle': 'Utilisateurs',
  'pages.users.user': 'Utilisateur',
  'utils.date.180Days': 'Les 180 derniers jours',
  'utils.date.30Days': 'Les 30 derniers jours',
  'utils.date.7Days': 'Les 7 derniers jours',
  'utils.date.90Days': 'Les 90 derniers jours',

  'pages.leadSource.manual.chooseStatus': 'Sélectionner statut',
  'components.leadSourceSwitcher.csvFile': 'Fichier CSV',
  'components.leadSourceSwitcher.webhook': 'Ajouter lead',
  'components.leadSourceSwitcher.manual': 'Manuel',
  'pages.leadsources.manual.chooseStatus': 'Sélectionner source de lead',

  'pages.lead.tasks.taskTitle': 'Titre',
  'pages.lead.tasks.taskDescription': 'Description',
  'pages.lead.tasks.taskDue': "Date d'échéance",
  'pages.leads.tasks.taskLead': 'Lead',
  'pages.lead.tasks.new': 'Nouvelle tâche',
  'pages.leads.tasks.showDone': 'Achevés',
  'pages.leads.tasks.createTask': 'Nouvelle tâche',
  'pages.leads.tasks.showOpen': 'Ouverts',

  'pages.leadsources.import': 'Importer',
  'pages.leadsources.delete': 'Supprimer',
  'pages.leadsources.sesarch': 'Recherche',
  'pages.leadsoruces.csv.new': 'Nouvelle source CSV',
  'pages.leadsources.actions': 'Actions',
  'pages.leadsources.csv.upload': 'Importer CSV',
  'pages.leadsources.csv.map': 'Attribuer les colonnes',
  'pages.leadsources.csv.preview': 'Aperçu & Enregistrer',
  'pages.leadsources.csv.entryState': 'Statut attribué',
  'pages.leadsources.csv.state': 'Statut',
  'pages.leadsources.csv.dropFile': 'Sélectionner le fichier (CSV) ou le faire glisser ici',
  'pages.leadsource.csv.parseError': 'Erreur de lecture du fichier CSV',
  'pages.leadsources.csv.parseSuccess': 'Fichier CSV lu avec succès',
  'pages.leadsources.csv.mappingSuccessful': 'Données attribuées avec succès',
  'pages.leadsources.csv.errorReadingFile': 'Erreur de lecture du fichier',
  'pages.leadsources.name.placeholder': 'Saisir le nom de la source de leads',
  'pages.emailAutomation.type': 'Type',
  'pages.emailAutomation.fromState': 'De',
  'pages.emailAutomation.to': 'à',
  'pages.emailAutomation.emailSubject': 'Sujet',
  'pages.emailAutomation.emailContent': 'Contenu',
  'pages.emailAutomation.locationManager': 'Responsable du site',
  'pages.emailAutomation.assignee': 'Responsable',
  'pages.emailAutomation.lead': 'Lead',
  'pages.emailAutomation.otherUsers': 'Autre utilisateur',
  'pages.location.add': 'Ajouter',
  'pages.emailAutomation.name': 'Nom',
  'pages.emailAutomation.status': 'Statut',
  'pages.emailAutomation.active': 'Actif',
  'pages.emailAutomation.trigger': 'Déclencheur',
  'pages.emailAutomation.actions': 'Actions',
  'pages.emailAutomation.removeConfirm': 'Êtes-vous sûr de supprimer cette automatisation?',
  'pages.emailAutomation.newAutomation': 'Nouvelle automatisation',
  'pages.emailAutomation.title': 'Automatisation',
  'pages.emailAutomation.titleRequired': 'Veuillez saisir un titre',
  'pages.emailAutomation.toRequried': "Veuillez saisir l'e-mail du destinataire.",
  'pages.emailAutomation.selectPerson': 'Veuillez saisir un destinataire',
  'pages.emailAutomation.typeRequired': 'Veuillez choisir un type',

  ...transformObjectToLocaleMap('pages.settings.visibility.example', {
    hint: "Vous pouvez définir des règles d'affichage en filtrant certaines pages en saisissant leur URL voire certains paramètres",
    exampleTitle: 'Exemples',
    urls: [
      {
        value: '/cuisine/i',
        description: 'Toutes les pages de la section "cuisine"',
      },
      {
        value: '/cuisine/(.*)/i',
        description: 'Toutes les sous-pages de la section "cuisine"',
      },
      {
        value: '/https://domain.com/cuisine/i',
        description: 'Toutes les pages qui commencent par "https://domain.com/cuisine"',
      },
    ],
  }),

  // endregion
  'pages.leads.assignLead': 'Attribuer un lead',
  'pages.leads.leadGeneration': 'Génération de lead',
  'pages.leads.leadManagement': 'Suivi des leads',
  'pages.leads.filters.status.placeholder': 'Statut',
  'pages.leads.filters.marketingChannel.placeholder': 'Canal marketing',
  'pages.leads.filters.priority.placeholder': 'Priorité',
  'pages.lead.fields.name': 'Nom',
  'pages.lead.fields.email': 'E-mail',
  'pages.lead.fields.assignee': 'Personne attribuée',
  'pages.lead.fields.comments': 'Commentaires',
  'pages.lead.fields.priority': 'Priorité',
  'pages.lead.fields.createdAt': 'Date de création',
  'pages.lead.fields.appointmentAt': 'Date de RDV',
  'pages.lead.fields.updatedAt': "Date d'actualisation",
  'pages.lead.fields.dealSize': "Montant de l'affaire",
  'pages.lead.fields.leadSource': 'Source',
  'pages.lead.fields.phone': 'Téléphone',
  'pages.lead.fields.branch': 'Filiale',
  'pages.lead.fields.status': 'Statut',
  'pages.lead.fields.marketingChannel': 'Canal marketing',
  'pages.lead.fields.note': 'Commentaire',
  'pages.lead.toolbar.view.kanban': 'Vue kanban',
  'pages.lead.toolbar.view.table': 'Tableau',
  'pages.lead.toolbar.view.calendar': 'Calendrier',
  'pages.lead.toolbar.reload': 'Recharger',
  'pages.lead.toolbar.filters': 'Filtres',
  'pages.lead.toolbar.propertyVisibilitySettings': 'Propriétés visibles',
  'pages.lead.toolbar.heatMapView': 'Carte de chaleur',
  'pages.lead.toolbar.notHeatMapView': 'Vue standard',
  'pages.lead.sort.highestPriority': 'Priorité la plus élevée',
  'pages.lead.sort.nameAscending': 'Nom A-Z',
  'pages.lead.sort.nameDescending': 'Nom Z-A',
  'pages.lead.sort.createdAscending': 'Créé récent-ancien',
  'pages.lead.sort.createdDescending': 'Créé ancien-récent',
  'pages.lead.sort.modifiedAscending': 'Modifié récent-ancien',
  'pages.lead.sort.modifiedDescending': 'Modifié ancien-récent',
  'pages.lead.toolbar.statusManagementSettings': 'Gérer statuts',
  'pages.lead.toolbar.settings': 'Paramètres',
  'pages.lead.visibilitySettings.search': 'Rechercher...',
  'pages.lead.visibilitySettings.shown': 'Visibles',
  'pages.lead.visibilitySettings.hidden': 'Cachés',
  'pages.lead.status.selectDealState': 'Affaire',
  'pages.lead.status.removeStatusConfirmation': 'Êtes-vous sûr de supprimer ce statut?',
  'pages.lead.status.selectLifeCycleAutomation': 'Actualiser lorsque',
  'pages.lead.status.new': 'Ajouter un statut',
  'pages.lead.status.lifecycle.manual': 'Manuel',
  'pages.lead.status.lifecycle.appointmentBooked': 'Sur RDV demandé',
  'pages.lead.status.lifecycle.appointmentCancelled': 'Sur RDV annulé',
  'pages.leads.status.magicFields.settingsTitle': 'Modifier champs magiques',
  'pages.leads.status.magicFields.settingsDescription':
    "Vous pouvez spécifier des champs à afficher en cas de changement de statut d'un lead afin que les agents saisissent les données plus rapidement et plus efficacement.",
  'pages.leads.status.magicFields.modalTitle': 'Avis de saisie',
  'pages.leads.status.magicFields.modal.mandatoryFields.errorMessage':
    'Les champs obligatoires ne sont pas tous définis.',
  'pages.leads.status.magicFields.modal.mandatoryFields.title': 'Champs facultatifs',
  'pages.leads.status.magicFields.modal.mandatoryFields.description':
    'Vous devez définir toutes ces valeurs pour enregistrer les modifications de statut.',
  'pages.leads.status.magicFields.modal.optionalFields.title': 'Champs obligatoires',
  'pages.leads.status.magicFields.modal.optionalFields.description': '',
  'pages.leads.status.magicFields.mandatory': 'Obligatoire',
  'pages.leads.status.magicFields.optional': 'Facultatif',
  'pages.leads.status.magicFields.notRequired': 'Non requis',
  'pages.lead.drawer.details': 'Détails du lead',
  'pages.lead.drawer.activities': 'Activités',
  'pages.lead.drawer.tasks': 'Tâches',
  'pages.lead.drawer.comments': 'Commentaires',
  'pages.lead.action.archive.confirmation': "Êtes-vous sûr d'archiver ce lead?",
  'pages.lead.comment.newComment': 'Ajouter un commentaire',
  'pages.lead.tasks.title': 'Titre',
  'pages.lead.tasks.showDone': 'Achevés',
  'pages.lead.tasks.showOpen': 'Ouverts',
  'pages.leads.todayView.appointments': 'Rendez-vous',
  'pages.leads.todayView.goToCalendar': 'Accéder au calendrier',
  'pages.leads.todayView.today': "Aujourd'hui",
  'pages.leads.todayView.tomorrow': 'Demain',
};
