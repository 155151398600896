import {useSelector} from "umi";
import {Space} from "antd";
import React from "react";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  CommentOutlined,
  DeliveredProcedureOutlined,
  EnvironmentOutlined,
  EuroOutlined,
  FormOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  TeamOutlined
} from '@ant-design/icons';
import {LEAD_PROPERTIES} from "@/components/Kanban/VisibilitySettings/PropertyVisibilitySettings";
import LeadName from "@/components/Lead/LeadName";
import LeadEmail from "@/components/Lead/LeadEmail";
import LeadSource from "@/components/Lead/LeadSource";
import LeadDealSize from "@/components/Lead/LeadDealSize";
import LeadMarketingChannel from "@/components/Lead/LeadMarketingChannel";
import LeadPhone from "@/components/Lead/LeadPhone";
import LeadAssigneeOverview from "@/components/Lead/LeadAssigneeOverview";
import LeadStatus from "@/components/Lead/LeadStatus";
import LeadCreatedAt from "@/components/Lead/LeadCreatedAt";
import LeadUpdatedAt from "@/components/Lead/LeadUpdatedAt";
import LeadAppointmentAt from "@/components/Lead/LeadAppointmentAt";

import {LeadModel} from "@/typings/models/Lead";

import styles from "./index.less"
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import LeadPriority from "@/components/Lead/LeadPriority";
import LeadBranch from "@/components/Lead/LeadBranch";
import LeadNote from "@/components/Lead/LeadNote";
import isEmpty from "lodash/isEmpty";


const LEAD_PROPERTY_ICONS = {
  [LEAD_PROPERTIES.NAME]: null,
  [LEAD_PROPERTIES.EMAIL]: <MailOutlined/>,
  [LEAD_PROPERTIES.ASSIGNEE]: <TeamOutlined/>,
  [LEAD_PROPERTIES.COMMENTS]: <CommentOutlined/>,
  [LEAD_PROPERTIES.CREATED_AT]: <CalendarOutlined/>,
  [LEAD_PROPERTIES.UPDATED_AT]: <ClockCircleOutlined/>,
  [LEAD_PROPERTIES.APPOINTMENT_AT]: <CalendarOutlined/>,
  [LEAD_PROPERTIES.DEAL_SIZE]: <EuroOutlined/>,
  [LEAD_PROPERTIES.PHONE]: <PhoneOutlined/>,
  [LEAD_PROPERTIES.MARKETING_CHANNEL]: <GlobalOutlined/>,
  [LEAD_PROPERTIES.BRANCH]: <EnvironmentOutlined/>,
  [LEAD_PROPERTIES.SOURCE]: <DeliveredProcedureOutlined />,
  [LEAD_PROPERTIES.NOTE]: <FormOutlined />,
};

const LEAD_PROPERTY_COMPONENTS = {
  [LEAD_PROPERTIES.NAME]: LeadName,
  [LEAD_PROPERTIES.EMAIL]: LeadEmail,
  [LEAD_PROPERTIES.PHONE]: LeadPhone,
  [LEAD_PROPERTIES.MARKETING_CHANNEL]: LeadMarketingChannel,
  [LEAD_PROPERTIES.DEAL_SIZE]: LeadDealSize,
  [LEAD_PROPERTIES.CREATED_AT]: LeadCreatedAt,
  [LEAD_PROPERTIES.UPDATED_AT]: LeadUpdatedAt,
  [LEAD_PROPERTIES.APPOINTMENT_AT]: LeadAppointmentAt,
  [LEAD_PROPERTIES.ASSIGNEE]: LeadAssigneeOverview,
  [LEAD_PROPERTIES.STATUS]: LeadStatus,
  [LEAD_PROPERTIES.PRIORITY]: LeadPriority,
  [LEAD_PROPERTIES.BRANCH]: LeadBranch,
  [LEAD_PROPERTIES.SOURCE]: LeadSource,
  [LEAD_PROPERTIES.NOTE]: LeadNote,
}

export const LEAD_PROPERTY_COLUMN_SETTINGS = {
  [LEAD_PROPERTIES.NAME]: {
    title: {
      id: 'pages.lead.fields.name',
      defaultMessage: 'Name'
    },
    extraProps: {
      fixed: 'left'
    }
  },
  [LEAD_PROPERTIES.EMAIL]: {
    title: {
      id: 'pages.lead.fields.email',
      defaultMessage: 'Email'
    }
  },
  [LEAD_PROPERTIES.ASSIGNEE]: {
    title: {
      id: 'pages.lead.fields.assignee',
      defaultMessage: 'Assignee'
    },
  },
  [LEAD_PROPERTIES.CREATED_AT]: {
    title: {
      id: 'pages.lead.fields.createdAt',
      defaultMessage: 'Create date'
    }
  },
  [LEAD_PROPERTIES.UPDATED_AT]: {
    title: {
      id: 'pages.lead.fields.updatedAt',
      defaultMessage: 'Update date'
    }
  },
  [LEAD_PROPERTIES.APPOINTMENT_AT]: {
    title: {
      id: 'pages.lead.fields.appointmentAt',
      defaultMessage: 'Appointment date'
    }
  },
  [LEAD_PROPERTIES.DEAL_SIZE]: {
    title: {
      id: 'pages.lead.fields.dealSize',
      defaultMessage: 'Deal size'
    }
  },
  [LEAD_PROPERTIES.LEAD_SOURCE]: {
    title: {
      id: 'pages.lead.fields.leadSource',
      defaultMessage: 'Source'
    }
  },
  [LEAD_PROPERTIES.PHONE]: {
    title: {
      id: 'pages.lead.fields.phone',
      defaultMessage: 'Phone'
    }
  },
  [LEAD_PROPERTIES.MARKETING_CHANNEL]: {
    title: {
      id: 'pages.lead.fields.marketingChannel',
      defaultMessage: 'Channel'
    }
  },
  [LEAD_PROPERTIES.STATUS]: {
    title: {
      id: 'pages.lead.fields.status',
      defaultMessage: 'Status'
    }
  },
  [LEAD_PROPERTIES.PRIORITY]: {
    title: {
      id: 'pages.lead.fields.priority',
      defaultMessage: 'Priority'
    }
  },
  [LEAD_PROPERTIES.BRANCH]: {
    title: {
      id: 'pages.lead.fields.branch',
      defaultMessage: 'Branch'
    }
  }
}
export const LeadPropertyComponent = ({lead, property, isTable}) => {
  const Component = LEAD_PROPERTY_COMPONENTS[property]
  if (!Component) return null
  const Presentation = Component({lead, isTable}) && <Component lead={lead} isTable={isTable}/>

  if (!Presentation) return null

  return Presentation
}

type IOrderedLeadPropertiesType = { lead: LeadModel, showAllProperties?: boolean, spaceSize?: number, overrideVisibleProperties?: string[] }
export const LeadCardOrderedProperties = ({lead, showAllProperties, spaceSize, overrideVisibleProperties = []}: Readonly<IOrderedLeadPropertiesType>) => {
  const visibleProperties = useSelector(showAllProperties ?
    PreferencesSelectors.selectAllLeadsProperties :
    PreferencesSelectors.selectShownLeadsProperties
  ) || []

  const spaceProps = {}

  const getVisibleProperties = () => isEmpty(overrideVisibleProperties) ? visibleProperties : overrideVisibleProperties

  if (spaceSize) spaceProps.size = spaceSize
  return <Space direction={"vertical"} {...spaceProps}>
    {getVisibleProperties().map(property => {
      const Presentation = LeadPropertyComponent({lead, property})
      if (!Presentation) return null
      const Icon = LEAD_PROPERTY_ICONS[property]

      return <Space key={property} className={styles.propertyRow}>
        {Icon && <div className={styles.propertyIcon}>{Icon}</div>}
        {Presentation && <div className={styles.propertyPresentation}>{Presentation}</div>}
      </Space>
    }).filter(property => property)}
  </Space>
}
