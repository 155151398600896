export const selectLead = (state: DefaultRootState) => state.leads

export const selectLeads = (state: DefaultRootState) => state?.leads?.list || {}
export const selectGroupedLeads = (state: DefaultRootState) => state?.leads?.groupedList || {}
export const selectFieldChangeLoading = (state: DefaultRootState) => state?.leads?.changeLoadings || {}
export const selectLeadFilters = (state: DefaultRootState) => state?.leads?.filters
export const selectAssigneeFilter = (state: DefaultRootState) => state?.leads?.filters?.assignee
export const selectLeadKanbanView = (state: DefaultRootState) => state?.leads?.viewSettings?.leadKanbanView
export const selectPropertyVisibilitySettings = (state: DefaultRootState) => state?.leads?.viewSettings?.propertyVisibility
export const selectVisibleProperties = (state: DefaultRootState) => state?.leads?.viewSettings?.propertyVisibility?.shown || []
export const selectPerformanceChartFilters = (state: DefaultRootState) => state?.leads?.performanceChartFilters
export const selectPerformanceChartData = (state: DefaultRootState) => state?.leads?.leadsReportData || []
export const selectFunnelChartData = (state: DefaultRootState) => state?.leads?.funnelReportData
export const selectRoiFunnelChartData = (state: DefaultRootState) => state?.leads?.roiFunnelReportData

export const selectStatusSettings = (state: DefaultRootState) => state?.leads?.statusSettings || []

export const selectLeadAssignees = (state: DefaultRootState) => state?.leads?.assigneeList?.data || []

export const selectUnassignedLeads = (state: DefaultRootState) => state?.leads?.unassignedLeadsList || {}
export const selectAssignmentDrawerVisible = (state: DefaultRootState) => state?.leads?.assignmentDrawerVisible
export const selectTasks = (state: DefaultRootState) => state?.leads?.taskList
export const selectAssigneeByAssigneeId = (state: DefaultRootState, assigneeId) => state?.leads?.assigneeList?.find(assignee => assignee.id === assigneeId)
export const selectForcedAssignmentLeadId = (state: DefaultRootState) => state?.leads?.forcedAssignmentLeadId
export const selectAddLeadDrawer = (state: DefaultRootState) => state?.leads?.addLeadDrawer
export const selectLeadDetailsDrawer = (state: DefaultRootState) => state?.leads?.leadDetailsDrawer
export const selectLeadDetails = (state: DefaultRootState, leadId) => state?.leads?.details?.[leadId]
export const selectArchiveLoading = (state: DefaultRootState, leadId) => state?.leads?.archiveLoading?.[leadId]
export const selectArchivedLeads = (state: DefaultRootState) => state?.leads?.archivedLeads || {}
export const selectIsLeadArchived = (state: DefaultRootState, leadId) => state?.leads?.archivedLeads?.[leadId]

export const selectAllLeadsData = (state: DefaultRootState) => state?.leads?.leads
export const selectLeadData = (state: DefaultRootState, leadId) => state?.leads?.leads?.[leadId]

export const selectLeadFields = (state: DefaultRootState) => state?.leads?.leadFields || { default: [], meta: [] }

export const selectLeadsServiceRequests = (state: DefaultRootState) => state?.leads?.serviceRequests
