import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Select,
  Row,
  Col,
  Spin,
  Alert,
  Typography,
  Form,
  message,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import * as LeadSelectors from "@/selectors/leadSelectors";
import { useSelector, useDispatch, useIntl, FormattedMessage as F } from 'umi';
import { transformObjectToPayloadFields } from './utils';
import isEmpty from 'lodash/isEmpty';

const { Text } = Typography;

const MappingConfigurationStep = ({
  isNew,
  config,
  handleMappingSubmit,
  onClose,
  prevStep,
}) => {
  const [form] = Form.useForm();
  useSelector(({ location: { userBranches } }) => ({
    userBranches,
  }));
  const { systemFields, leadSourceDetail, updating } = useSelector((state: DefaultRootState) => ({
    systemFields: LeadSelectors.selectLeadFields(state),
    leadSourceDetail: state.leads.leadSourceDetail?.lead_source,
    updating: state.loading.effects['lead/updateWebhookLeadSource'],
  }));
  const dispatch = useDispatch();

  const [payloadFields, setPayloadFields] = useState([]);
  const [isPolling, setIsPolling] = useState(false);
  const intervalIdRef = useRef([]); // Use useRef to store interval IDs

  const fetchLeadConfig = (id) => {
    dispatch({
      type: 'leads/fetchLeadSource',
      payload: { id },
    });
  };

  const intl = useIntl();

  useEffect(() => {
    return () => {
      // Clear all intervals when the component unmounts
      intervalIdRef.current.forEach(clearInterval);
    };
  }, []);

  useEffect(() => {
    debugger

    if (leadSourceDetail?.id && isEmpty(leadSourceDetail?.configuration?.last_payload && !updating) && isEmpty(leadSourceDetail?.configuration?.mappings)) {
      startPolling(leadSourceDetail?.id);
    }
  }, [JSON.stringify(leadSourceDetail)]);

  const startPolling = (id) => {
    setIsPolling(true);
    const intervalId = setInterval(() => {
      fetchLeadConfig(id);
    }, 10000); // Poll every 10 seconds
    intervalIdRef.current.push(intervalId); // Store the interval ID
  };

  useEffect(() => {
  console.log('leadSourceDetail?.configuration', JSON.stringify(leadSourceDetail?.configuration));

    if (!isEmpty(leadSourceDetail?.configuration?.last_payload)) {
      // Clear all intervals when the last payload is received
      intervalIdRef.current.forEach(clearInterval);
      intervalIdRef.current = []; // Reset the interval IDs array
      setIsPolling(false);
      message.success(intl.formatMessage({id: 'pages.leadsources.webhook.successfulMapping'}));
    }
  }, [leadSourceDetail?.configuration?.last_payload]);


  useEffect(() => {
    console.log('leadSourceDetail?.configuration', JSON.stringify(leadSourceDetail?.configuration));

    if (!isEmpty(leadSourceDetail?.configuration)) {
      if (leadSourceDetail?.configuration?.last_payload) {
        const fields = transformObjectToPayloadFields(
          leadSourceDetail?.configuration?.last_payload,
        );
        setPayloadFields(fields);
      }
      const mappings = config?.configuration?.mappings?.reduce((acc, mapping) => {
        acc[mapping.target_field] = mapping.sources;
        return acc;
      }, {});
      form.setFieldsValue({ ...mappings });
    }
  }, [JSON.stringify(leadSourceDetail?.configuration), form]);

  return (
    <>
      {isEmpty(leadSourceDetail?.configuration?.last_payload) &&
        leadSourceDetail?.configuration?.webhook_url && (
          <>
            <Row justify="center" style={{ marginTop: 24 }}>
              <Alert
                style={{ backgroundColor: '#eaffea' }}
                icon={<CheckCircleOutlined />}
                message={
                  !isNew && isEmpty(leadSourceDetail?.configuration?.last_payload)
                    ? intl.formatMessage({id: 'pages.leadsources.webhook.noSampleData'})
                    : intl.formatMessage({id: 'pages.leadsources.webhook.created'})  
                }
                description={
                  <>
                    <Text>Webhook URL: </Text>
                    <Text copyable style={{ marginLeft: 10 }}>
                      {leadSourceDetail?.configuration?.webhook_url}
                    </Text>{' '}
                  </>
                }
                type="info"
                showIcon
              />
              
            </Row>
            <Row justify="center" style={{ marginTop: 24 }}>
              <Form.Item>
                <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
                  <F id='pages.leadsources.webhook.later' />
                </Button>
              </Form.Item>
            </Row>
          </>
        )}
      {isPolling && isEmpty(leadSourceDetail?.configuration?.last_payload) && (
        <Row justify="center" style={{ marginTop: 24 }}>
          <Spin tip={intl.formatMessage({id: 'pages.leadsources.webhook.checkingForData'})} />
          <Typography.Text style={{ marginLeft: 10 }} type="secondary">
            <F id='pages.leadsources.webhook.polling' />{' '}
          </Typography.Text>
        </Row>
      )}
      {!isEmpty(leadSourceDetail?.configuration?.last_payload) && (
        <Form layout="vertical" form={form} onFinish={handleMappingSubmit}>
          <Row gutter={16} style={{ marginBottom: 24 }}>
            {(systemFields?.default || [])?.map((field) => (
              <Col span={12} key={field}>
                <Form.Item
                  key={field.key}
                  label={field.label}
                  name={field.key}
                  rules={[{ required: field.required, message: `${field.label}` + intl.formatMessage({id: 'pages.common.required'}) }]}
                  style={{ marginBottom: 20 }}
                  required={field.required}
                  tooltip={field.tooltip}
                >
                  <Select
                    mode="multiple"
                    placeholder={intl.formatMessage({id: 'pages.common.required'}) + ` "${field.label}"`}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {payloadFields?.map((field) => (
                      <Option key={field.key} value={field.key}>
                        {field.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Form.Item>
            <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
              <F id="pages.common.cancel" />
            </Button>
            <Button style={{ margin: '0 8px' }} onClick={prevStep}>
            <F id="pages.common.previous" />
            </Button>
            <Button type="primary" htmlType="submit">
            <F id="pages.common.submit" />
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default MappingConfigurationStep;