import React from 'react';
import {Space, Tooltip} from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import {getLanguageFromUmiLocale} from "@/utils/locale";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.locale(getLanguageFromUmiLocale())

const PrettyDate = ({ date }) => {

  // Format the date to be relative (e.g., "2 days ago")
  const formattedDate = dayjs(date).fromNow();

  // Optionally, show a tooltip with the exact date
  const exactDate = dayjs(date).format('YYYY-MM-DD HH:mm');

  return (
    <Tooltip title={exactDate}>
      <span>{formattedDate}</span>
    </Tooltip>
  );
};

export const PrettyDateWithTime = ({ date }) => {

  // Format the date to be relative (e.g., "2 days ago")
  const formattedDate = dayjs(date).fromNow();
  const formattedTime = dayjs(date).format('HH:mm')
  // Optionally, show a tooltip with the exact date
  const exactDate = dayjs(date).format('YYYY-MM-DD HH:mm');

  return (
    <Tooltip title={exactDate}>
      <Space>
        <span>{formattedDate}</span>
        <span>{formattedTime}</span>
      </Space>
    </Tooltip>
  );
};

export const PrettyDuration = ({start, end}) => {
 const startDate = dayjs(start)
 const endDate = dayjs(end)

 const duration = dayjs.duration(endDate.diff(startDate));

 return duration.hours() + ' h'
}

export const PrettyHour = ({date}) => {
  const dateObject = dayjs(date)
  return dateObject.format("HH:mm")
}
export default PrettyDate;
