import React, {useState} from 'react';
import MyPageContainer from "@/components/MyPageContainer";
import {Col, Divider, Row, Space} from "antd";
import LeadTasksOverview from "@/components/Lead/LeadTasks/LeadTasksOverview";
import {NavLink, useDispatch} from "umi";
import {TasksTable} from "@/pages/Tasks/TasksTable";
import {fetchCalendarViewLeads, fetchLeadTasksList} from "@/services/api/lead";
import {LeadsServiceRequestsTable} from "@/components/Kanban/LeadsServiceRequestsTable";
import dayjs from "dayjs";

const TodaySalesAgentView: React.FC = () => {

  const [taskList, setTaskList] = useState([])
  const [taskListForTomorrow, setTaskListForTomorrow] = useState([])
  const [serviceRequestList, setServiceRequestList] = useState([])
  const [serviceRequestListForTomorrow, setServiceRequestListForTomorrow] = useState([])
  const [loading, setLoading] = useState(false)
  const [serviceRequestsLoading, setServiceRequestsLoading] = useState(false)
  const dispatch = useDispatch()
  const fetchTaskList = () => {
    setLoading(true)
    const today = dayjs().format("YYYY-MM-DD")
    fetchLeadTasksList({
      query: {
        due_to: today,
      }
    }).then(response => {
      setLoading(false)
      setTaskList(response.data.data)
    })
  }

  const fetchTaskListForTomorrow = () => {
    setLoading(true)
    const tomorrow = dayjs().add(1, 'day').format("YYYY-MM-DD")
    fetchLeadTasksList({
      query: {
        due_to: tomorrow,
        due_from: tomorrow,
      }
    }).then(response => {
      setLoading(false)
      setTaskListForTomorrow(response.data.data)
    })
  }

  const fetchServiceRequestsList = () => {
    const today = dayjs().format("YYYY-MM-DD")
    setServiceRequestsLoading(true)
    fetchCalendarViewLeads({query: {
        start_date: today,
        end_date: today,
        status: 'confirmed,tentative',
        all: 1
      }}).then((response) => {
      setServiceRequestList(response.data.data)
      setServiceRequestsLoading(false)
    })
  }

  const fetchServiceRequestsListForTomorrow = () => {
    const tomorrow = dayjs().add(1, 'day').format("YYYY-MM-DD")
    setServiceRequestsLoading(true)
    fetchCalendarViewLeads({query: {
        start_date: tomorrow,
        end_date: tomorrow,
        status: 'confirmed,tentative',
        all: 1
      }}).then((response) => {
      setServiceRequestListForTomorrow(response.data.data)
      setServiceRequestsLoading(false)
    })
  }
  return (
    <MyPageContainer extraProps={{actions: [<LeadTasksOverview/>]}}>
      <Row gutter={12}>
        <Col xl={8}>
          <Divider><Space>
            <span>Appointments</span>
            <NavLink to={'/calendar'}>Go to Calendar</NavLink>
          </Space></Divider>
          <LeadsServiceRequestsTable loading={serviceRequestsLoading} request={fetchServiceRequestsList}
                                     data={serviceRequestList}/>
        </Col>
        <Col xl={16}>
          <Divider>Tasks</Divider>
          <TasksTable hideCreate hideShowDone data={taskList} loading={loading} request={fetchTaskList}/>
        </Col>
      </Row>
      <Divider><h2>Tomorrow</h2></Divider>
      <Row gutter={12}>
        <Col xl={8}>
          <LeadsServiceRequestsTable loading={serviceRequestsLoading} request={fetchServiceRequestsListForTomorrow}
                                     data={serviceRequestListForTomorrow}/>
        </Col>
        <Col xl={16}>
          <TasksTable hideCreate hideShowDone data={taskListForTomorrow} loading={loading} request={fetchTaskListForTomorrow}/>
        </Col>
      </Row>
    </MyPageContainer>
  );
};

export default TodaySalesAgentView
