import React, {useEffect} from 'react';
import {Badge, Button, List, Popover} from 'antd';
import {BellOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "umi";
import * as LeadSelectors from "@/selectors/leadSelectors";
import PrettyDate from "@/components/PrettyDate";

const LeadTasksOverview = () => {
  const dispatch = useDispatch()
  const taskList = useSelector(LeadSelectors.selectTasks)

  useEffect(() => {
    dispatch({
      type: 'leads/fetchLeadsTasksList',
    });
  }, [])

  const openTasks = (taskList || []).filter(task => !task.is_completed)
  const popoverContent = (
    <div style={{ width: 300 }}>
      <List
        itemLayout="horizontal"
        dataSource={openTasks}
        renderItem={(task) => (
          <List.Item>
            <List.Item.Meta
              title={<span>{task.title}</span>}
              description={<PrettyDate date={task.due_date}/>}
            />
          </List.Item>
        )}
      />
      <Link to="/tasks">
        <Button type="link" block>
          View all tasks
        </Button>
      </Link>
    </div>
  );

  return (
    <Popover content={popoverContent} title="Open Tasks" trigger="click" placement={"bottomRight"}>
      <Badge count={openTasks.length} size="small">
        <Button shape="circle" icon={<BellOutlined />} />
      </Badge>
    </Popover>
  );
};

export default LeadTasksOverview;
