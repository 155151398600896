import * as React from 'react';
import { useEffect } from 'react';
import NestedDrawerButton from '@/components/buttons/NestedDrawerButton';
import styles from '@/components/menu/MenuHeader/index.less';
import { UserAddOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useDispatch, useModel } from 'umi';
import { F } from '@/utils/intl';
import HotkeyHint from '@/components/Hotkey';
import LeadAssignmentDrawer from '@/components/Lead/LeadAssignmentDrawer';
import LeadDetailsDrawer from '@/components/Lead/LeadDetailsDrawer';

const LeadManagementQuickActions: React.FC = () => {
  const { initialState } = useModel('@@initialState');
  const dispatch = useDispatch();

  const HOTKEY_MAP = {
    ADD_LEAD: 'g',
    ASSIGN_LEAD: 'l',
  };

  const onAddLeadClick = () => {
    dispatch({
      type: 'leads/toggleAddLeadsDrawer',
    });
  };
  const onAssignLeadClick = () => {
    dispatch({
      type: 'leads/toggleAssigneeDrawer',
    });
  };
  useEffect(() => {
    const commandHandlers = {
      [HOTKEY_MAP.ADD_LEAD]: onAddLeadClick,
      [HOTKEY_MAP.ASSIGN_LEAD]: onAssignLeadClick,
    };
    const handleKeyDown = (event) => {
      if (event.metaKey || event.ctrlKey) {
        const handler = commandHandlers[event.key.toLowerCase()];
        if (handler) handler();
      }
    };

    // Add the event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '10px 20px',
      }}
    >
      <NestedDrawerButton
        block
        className={styles.leadManagementQuickActionButton}
        onClick={onAddLeadClick}
      >
        {!initialState.collapsed ? (
          <div className={styles.buttonContent}>
            <Space>
              <UserAddOutlined />
              <F id={'pages.leads.addLead'} defaultMessage={'Add Lead'} />
            </Space>
            <span className={styles.shortcut}>
              <HotkeyHint hotkey={HOTKEY_MAP.ADD_LEAD} />
            </span>
          </div>
        ) : (
          <UserAddOutlined />
        )}
      </NestedDrawerButton>
      <NestedDrawerButton
        block
        className={styles.leadManagementQuickActionButton}
        onClick={onAssignLeadClick}
      >
        {!initialState.collapsed ? (
          <div className={styles.buttonContent}>
            <Space>
              <UserSwitchOutlined />
              <F id={'pages.leads.assignLead'} defaultMessage={'Assign Lead'} />
            </Space>
            <span className={styles.shortcut}>
              <HotkeyHint hotkey={HOTKEY_MAP.ASSIGN_LEAD} />
            </span>
          </div>
        ) : (
          <UserSwitchOutlined />
        )}
      </NestedDrawerButton>
      <LeadDetailsDrawer />
      <LeadAssignmentDrawer />
    </div>
  );
};
export default LeadManagementQuickActions;
