import {useSortable} from "@dnd-kit/sortable";
import {Col, Row, Spin, Tag} from "antd";
import classNames from "classnames";
import {useSelector} from "umi";
import React, {useState} from "react";

import {getKanbanCardStyles} from "@/components/Kanban/utils";
import LeadContextMenu from "@/components/Lead/LeadContextMenu";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors"
import * as LeadSelectors from "@/selectors/leadSelectors"
import {LEAD_PROPERTIES} from "@/components/Kanban/VisibilitySettings/PropertyVisibilitySettings";

import styles from "./styles.less"
import {LeadCardOrderedProperties} from "@/components/Lead/LeadProperties";
import isEmpty from "lodash/isEmpty";

type LeadCardProps = {
  disabled?: boolean,
  id: string,
  dragOverlay?: any,
  hideAssignee?: boolean,
  hideActions?: boolean
  disableDrag?: boolean
  archive?: boolean,
  overrideVisibleProperties?: string[]
  leadContextMenuProps?: {}
}
const LeadCard = React.memo((props: LeadCardProps) => {
  const {id, leadObject, dragOverlay, archive, overrideVisibleProperties, leadContextMenuProps = {}} = props;

  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, id)) || leadObject
  const [draggable, setDraggable] = useState(true)
  const visibleProperties = useSelector(PreferencesSelectors.selectShownLeadsProperties) || []
  const archiveLoading = useSelector((state: DefaultRootState) => LeadSelectors.selectArchiveLoading(state, id)) || false
  const isArchivedLead = useSelector((state: DefaultRootState) => LeadSelectors.selectIsLeadArchived(state, id)) || false
  if (isArchivedLead && !archive) return null
  const loading = archiveLoading
  const { isHeatMap } =
  useSelector((state: DefaultRootState) => ({
    isHeatMap: PreferencesSelectors.selectIsHeatMap(state),
  }));
  const disableDrag = !draggable || props.disableDrag || props.disabled
  const {
    setNodeRef,
    // setActivatorNodeRef,
    listeners,
    isDragging,
    // isSorting,
    // over,
    // overIndex,
    transform,
    transition,
    attributes
  } = useSortable({
    id: id,
    disabled: disableDrag,
    data: {
      type: "FIELD"
    }
  });

  const viewLeadDetails = () => {
    // dispatch({
    //   type: "customers/toggleDrawer",
    //   payload: {
    //     customer: lead
    //   }
    // })
  }


  const getVisibleProperties = () => isEmpty(overrideVisibleProperties) ? visibleProperties : overrideVisibleProperties

const getBackgroundColor = () => {
  const currentDate = new Date();
  const modifiedDate = new Date(lead?.modified);
  const dayDiff = Math.floor((currentDate - modifiedDate) / (1000 * 60 * 60 * 24));
  let color;
  if (dayDiff <= 1) {
      color = '#e8f5de';
  } else if (dayDiff <= 3) {
      color = '#f7f4d4';
  } else if (dayDiff <= 7) {
      color = '#f7e2d4';
  } else if (dayDiff <= 14) {
      color = '#f7d4d4';
  } else {
      color = '#f7cccc';
  }
  return color;
}

if (!lead?.customer) return null
  return (
    <div
      ref={disableDrag ? null : setNodeRef}
      className={classNames(styles.kanbanCard, {
        [styles.draggable]: !disableDrag,
      })}
      onClick={viewLeadDetails}
      style={{
        ...getKanbanCardStyles({
          transform,
          transition,
          dragOverlay,
          isDragging,
        }),
        backgroundColor: isHeatMap ? getBackgroundColor() : null,
      }}
      {...attributes}
      {...listeners}
    >
      <Spin spinning={loading}>
        <Row justify="space-between">
          <Col span={18}>
            <LeadCardOrderedProperties overrideVisibleProperties={overrideVisibleProperties} lead={lead} />
          </Col>
          {!props.hideActions && (
            <Col span={6} style={{ textAlign: 'right', cursor: 'pointer' }}>
              <LeadContextMenu
                actionsFilter={['details']}
                lead={lead}
                setDraggable={setDraggable}
                {...(leadContextMenuProps || {})}
              />
            </Col>
          )}
        </Row>
        {getVisibleProperties().includes(LEAD_PROPERTIES.ASSIGNEE) && !props.hideAssignee && (
          <Row
            justify="space-between"
            style={{
              marginTop: '10px',
              color: '#777',
            }}
          ></Row>
        )}
        {isHeatMap && (
          <Row>
            <Tag color="blue">
              Modfied about{' '}
              {Math.floor((new Date() - new Date(lead.modified)) / (1000 * 60 * 60 * 24))} days ago
            </Tag>
          </Row>
        )}
      </Spin>
    </div>
  );
}, (prevProps, nextProps) => prevProps.id === nextProps.id);
export default LeadCard
