import merge from "lodash/merge";
import pick from "lodash/pick";
import omit from "lodash/omit";

import {selectCurrentJourneyId} from "@/models/journey";
import {processAPIResponse} from "@/utils/request";
import {selectDateFilter} from "@/models/date";
import {setChangeLoadingsUtility} from "@/utils/form";
import {message, notification} from "antd";
import * as LeadSelectors from "@/selectors/leadSelectors"
import {
  archiveLeadRequest,
  assignLeadRequest,
  createCSVLeadSourceRequest,
  createCSVLeadsRequest,
  createLeadRequest,
  createLeadTaskRequest,
  deleteLeadSource,
  editLeadsRequest,
  editLeadStatusRequest,
  fetchAssignees,
  fetchCalendarViewLeads,
  fetchFunnelChartData,
  fetchLeadFields,
  fetchLeadLifeCycleEventsSettings,
  fetchLeads,
  fetchLeadSources,
  fetchLeadStatusSettings,
  fetchLeadTasksList,
  fetchPerformanceChartData,
  fetchRoiFunnelChartData,
  fetchServices,
  fetchSingleLeadDetails,
  getLeadSource,
  reorderLeadStatusRequest,
  updateCSVLeadSourceRequest,
} from "@/services/api/lead";
import set from "lodash/set";
import get from "lodash/get";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import {fetchMeRequest} from "@/services/api/user";


const parseSort = (sortString) => {
  const sort_direction = sortString.startsWith('-') ? 'desc' : 'asc';
  const sort_by = sortString.replace('-', '').replace('+', '');
  return { sort_by, sort_direction };
};

export default {
  namespace: 'leads',

  state: {
    leadFields: {
      default: [],
      meta: []
    },
    list: {},
    states: [],
    details: {},
    changeLoadings: {},
    statusSettings: [],
    statusAutomationEvents: [],
    viewSettings: {
      leadKanbanView: true,
      isHeatmap: true,
      propertyVisibility: undefined
    },
    filters: {},
    lead: {
      drawer: {
        visible: false
      },
      status: {}
    },
    assigneeList: [],
    leadSourceList: [],
    serviceList: [],
    leadStatusList: [],
    unassignedLeadsList: [],
    performanceChartFilters: {},
    roiFunnelChartFilters: {},
    leadsReportData: {},
    funnelReportData: {},
    roiFunnelReportData: {},
    archiveLoading: {},
    archivedLeads: {},
    serviceRequests: {},
    addLeadDrawer: null,
    leadDetailsDrawer: null,
    assignmentDrawerVisible: false,
    taskList: [],
    addLead: {
      drawer: {
        visible: false
      }

    },
    leadSourceDetail: { lead_source: {} }

  },

  effects: {
    * changeFilter({ payload }, { call, put, select }) {
      yield put({
        type: 'modifyFilters',
        payload
      })
    },
    * fetchLeadsByUrl({ payload }, { call, put }) {
      const { response, data } = yield call(fetchLeads, { url: payload.url });
      if (payload.then) payload.then()
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeads',
            payload: data,
            options: payload
          })
        },
        * onError(error) {

        }
      })
    },
    * fetch({ payload = {} }: { payload: { search?: string, url?: string, order_by?: string } }, { call, put, select }) {
      const leadView = yield select(PreferencesSelectors.selectLeadView)
      const date = yield select(selectDateFilter)
      const filters = yield select(state => state.leads.filters)
      const filteredObj = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== null)
      );
      if (filteredObj.sort) {
        const { sort_by, sort_direction } = parseSort(filteredObj.sort)
        if (sort_by && sort_direction) {
          delete filteredObj.sort
          filteredObj.sort_by = sort_by
          filteredObj.sort_direction = sort_direction
        }
      }
      if (leadView === 'kanban'){
        filteredObj.group_by = 'lead_state'
        delete filteredObj.lead_state
      }
      const query = { ...date, ...filteredObj, ...payload };
      const journeyId = yield select(selectCurrentJourneyId)
      const { response, data } = yield call(fetchLeads, { params: { journeyId }, url: payload.url, query });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeads',
            payload: data,
            options: query
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchCalendarServiceRequest({ payload = {} }: { payload: { search?: string, url?: string, order_by?: string } }, { call, put, select }) {
      // For the MVP, we don't filter the date
      // const date = yield select(selectDateFilter)
      const filters = yield select(state => state.leads.filters)
      const filteredObj = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== null)
      );
      if (filteredObj.sort) {
        const { sort_by, sort_direction } = parseSort(filteredObj.sort)
        if (sort_by && sort_direction) {
          delete filteredObj.sort
          filteredObj.sort_by = sort_by
          filteredObj.sort_direction = sort_direction
        }
      }
      const query = { ...filteredObj, ...payload, status: 'confirmed,tentative', all: 1 };
      const { response, data } = yield call(fetchCalendarViewLeads, { url: payload.url, query });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveServiceRequests',
            payload: data,
            options: query
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchSingleLead({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const { leadId } = payload
      const { response, data } = yield call(fetchSingleLeadDetails, { params: { leadId } });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeadDetails',
            payload: data,
          })
        },
        * onError(error) {

        }
      }, 'data')
    },
    * fetchUnassignedLeads({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const query = { assignee: 'null', sort_by: 'firstname', sort_direction: 'desc', ...payload };
      const { response, data } = yield call(fetchLeads, { url: payload.url, query });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveUnassignedLeads',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchLeadStatuses({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const leadLifeCycleEventsSettings = yield call(fetchLeadLifeCycleEventsSettings);
      const { response, data } = yield call(fetchLeadStatusSettings);
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeadStatuses',
            payload: data
          })
        },
        * onError(error) {

        }
      }, 'data')
    },
    * fetchLeadsTasksList({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const { response, data } = yield call(fetchLeadTasksList, {});
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveTaskList',
            payload: data
          })
        },
        * onError(error) {

        }
      }, 'data')
    },
    * createLeadTask({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const { response, data } = yield call(createLeadTaskRequest, { data: payload.data });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'fetchLeadsTasksList'
          })
          payload.then && payload.then()
        },
        * onError(error) {

        }
      }, 'data')
    },
    * editLeadState({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const params = pick(payload, ['statusId'])
      const body = omit(payload, ['statusId', 'id'])
      const { response, data } = yield call(editLeadStatusRequest, { params, data: body });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'fetchLeadStatuses'
          })
          yield put({
            type: 'fetch'
          })
        },
        * onError(error) {

        }
      })
    },
    * changeLeadStatusOrder({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const { response, data } = yield call(reorderLeadStatusRequest, { data: payload });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'fetchLeadStatuses'
          })
          yield put({
            type: 'fetch'
          })
        },
        * onError(error) {

        }
      })
    },
    * edit({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const params = pick(payload, ['leadId', 'applyLate'])
      const body = omit(payload, ['leadId', 'applyLate'])
      const lead = yield select(state => LeadSelectors.selectLeadData(state, params.leadId))
      if (payload.lead_state_id){
        const statusSettings = yield select(LeadSelectors.selectStatusSettings)
        const leadState = statusSettings.find(leadStatus => leadStatus.id === payload.lead_state_id)
        console.log(leadState)
      }
      if (!params.applyLate) {
        yield put({
          type: 'editLeadLocally',
          payload
        })
      }
      const { response, data } = yield call(editLeadsRequest, { params, data: body });
      if (params.applyLate) {
        yield put({
          type: 'editLeadLocally',
          payload
        })
      }
      const loading = Object.keys(body).reduce((previousValue, key) => ({
        ...previousValue,
        [`${params.leadId}_${key}`]: true
      }), {})
      yield put({
        type: 'setChangeLoadingsForKey',
        payload: loading
      })
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'setChangeLoadingsForKey',
            payload: Object.keys(body).reduce((previousValue, key) => ({
              ...previousValue,
              [`${params.leadId}_${key}`]: false
            }), {})
          })
          yield put({
            type: 'editLeadDetails',
            payload: { lead: data }
          })
        },
        * onError(error) {

        }
      }, 'data')
    },
    * fetchPerformanceChartData({ payload = {} }: { payload }, { call, put, select }) {
      const date = yield select(selectDateFilter)
      let performanceChartFilters = pick(payload, ['group-by', 'metrics'])
      if (Array.isArray(performanceChartFilters.metrics)) {
        performanceChartFilters.metrics = performanceChartFilters.metrics
          .map((metric) => (metric === "Leads" ? 'count' : metric))
          .join(',');
      }
      performanceChartFilters = { ...yield select(state => state.leads.performanceChartFilters), ...performanceChartFilters }
      const query = { ...performanceChartFilters, create_at_from: date.start_date, create_at_to: date.end_date, group_by: payload?.group_by };
      const { response, data } = yield call(fetchPerformanceChartData, { url: payload.url, query });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'setPerformanceChartData',
            payload: data
          })
        },
        * onError(error) {
        }
      }, 'data')
    },
    * fetchFunnelChartData({ payload = {} }: { payload }, { call, put, select }) {
      const date = yield select(selectDateFilter)
      const query = { breakdown_by: payload?.breakdownBy, created_from: date.start_date, created_to: date.end_date };
      const { response, data } = yield call(fetchFunnelChartData, { url: payload.url, query });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'setFunnelChartData',
            payload: data
          })
        },
        * onError(error) {

        }
      }, 'data')
    },
    * fetchRoiFunnelChartData({ payload = {} }: { payload }, { call, put, select }) {
      const date = yield select(selectDateFilter)
      const chartFilters = yield select(state => state.leads.roiFunnelChartFilters)
      const query = {
        breakdown_by: payload?.breakdownBy,
        created_from: date.start_date,
        created_to: date.end_date, ...chartFilters
      };
      const { response, data } = yield call(fetchRoiFunnelChartData, { url: payload.url, query });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'setRoiFunnelChartData',
            payload: data
          })
        },
        * onError(error) {

        }
      }, 'data')
    },
    * fetchAssignees({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const { response, data } = yield call(fetchAssignees, { query: { all: 1 } });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'setAssignees',
            payload: {
              assigneeList: data
            }
          })
        },
        * onError(error) {

        }
      })
    },
    * assign({ payload }, { call, put, select }) {
      const { response, data } = yield call(assignLeadRequest, { payload });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'toggleAssigneeDrawer',
            payload: {}
          })
          yield put({
            type: 'fetch',
          })
        }
        , * onError(error) {
        }
      })
    },
    * assignSingle({ payload }, { call, put, select }) {
      const { assigneeId, leadId } = payload
      const lead = yield select(state => LeadSelectors.selectLeadData(state, leadId))
      const assignee = yield select(state => LeadSelectors.selectAssigneeByAssigneeId(state, assigneeId))
      if (assignee?.id === "2") {
        notification.success({
          message: 'Task Created',
          description: `Followup task to call ${lead.firstname} ${lead.lastname} has been successfully added for ${assignee.firstname} ${assignee.lastname}`,
          placement: 'topRight',
        });
        yield put({
          type: 'createDemoTask',
          payload: {
            lead
          }
        })
      }
      yield put({
        type: 'setLeadAssignee',
        payload: {
          leadId, assignee
        }
      })
      // yield processAPIResponse({ response, data }, {
      //   * onSuccess(data) {
      //     yield put({
      //       type: 'toggleAssigneeDrawer',
      //       payload: {}
      //     })
      //     yield put({
      //       type: 'fetch',
      //     })
      //   }
      //   , * onError(error) {
      //   }
      // })
    },
    * archive({ payload }, { call, put, select }) {
      const { leadId } = payload
      yield put({
        type: 'setArchiveLoading',
        payload: {
          [leadId]: true
        }
      })
      const { response, data } = yield call(archiveLeadRequest, { leadId })

      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'setArchiveLoading',
            payload: {
              [leadId]: false
            }
          })
          yield put({
            type: 'setArchivedLeads',
            payload: {
              [leadId]: true
            }
          })
        },
        * onError(error) {
          yield put({
            type: 'setArchiveLoading',
            payload: {
              [leadId]: false
            }
          })
        }
      })
    },
    * create({ payload }, { call, put, select }) {
      const { response, data } = yield call(createLeadRequest, {
        params: { sourceId: payload.sourceId },
        data: [{
          ...payload.data,
          priority: 0,
          meta: []
        }]
      });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'toggleAddLeadsDrawer'
          })
          yield put({
            type: 'fetch',
          })
          payload.intl && message.success(payload.intl.formatMessage({
            id: "pages.leads.createLeadSuccess",
            defaultMessage: 'Lead created successfully'
          }))
        }
        , * onError(error) {
          message.error(error?.message || payload.intl.formatMessage({
            id: "pages.leads.createLeadFailed",
            defaultMessage: 'Creating lead failed'
          }))
        }
      })

    },
    * fetchLeadSources({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const { response, data } = yield call(fetchLeadSources);
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'setLeadSources',
            payload: {
              leadSourceList: data
            }
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchServices({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const { response, data } = yield call(fetchServices);
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'setServices',
            payload: {
              serviceList: data
            }
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchLeadFields({ payload = {} }: { payload: { search?: string, url?: string } }, { call, put, select }) {
      const { response, data } = yield call(fetchLeadFields);
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeadFields',
            payload: data?.data
          })
        },
        * onError(error) {

        }
      })
    },

    * fetchMe({ payload = {} }, { call, put, select }) {
      const { response, data } = yield call(fetchMeRequest);
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'journey/gotJourneys',
            payload: data?.Journeys
          })
        },
        * onError(error) {

        }
      })
    },

    * createWebhookLeadSource({ payload }, { call, put, select }) {
      const { response, data } = yield call(createCSVLeadSourceRequest, {
        data: { ...payload.allData }
      });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'fetchMe',
          })
          yield put({
            type: 'fetchLeadSource',
            payload: { id: data?.data?.lead_source?.id }
          })
          try {
            if (payload?.cb) payload?.cb(null, data?.data);
          } catch (error) {
            console.error('Error in callback:', error);
          }
        }
        , * onError(error) {
          message.error(error?.error?.message)

        }
      })
    },
    * updateWebhookLeadSource({ payload }, { call, put, select }) {
      const { response, data } = yield call(updateCSVLeadSourceRequest, {
        data: { ...payload.allData },
      });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          try {
            yield put({
              type: 'fetchMe',
            })
            yield put({
              type: 'fetchLeadSource',
              payload: { id: data?.data?.lead_source?.id }
            })
            if (payload?.cb) payload?.cb(null, data?.data); // Invoke the callback on success
          } catch (error) {
            console.error('Error in callback:', error);
          }
        }
        , * onError(error) {

          message.error(error?.error?.message)

        }
      })


    },
    * createCSVLeadSource({ payload }, { call, put, select }) {
      const { response, data } = yield call(createCSVLeadSourceRequest, {
        data: { ...payload.allData }
      });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'fetchMe',
            payload: data?.data
          })
          yield put({
            type: 'createCSVLeads',
            payload: {
              ...payload, sourceId:
                data?.data?.lead_source?.id
            }
          })
        }
        , * onError(error) {
          message.error(error?.error?.message)

        }
      })


    },
    * createCSVLeads({ payload }, { call, put, select }) {
      const callback = payload?.cb
      payload = { ...payload.allData, lead_state_id: payload.allData?.incoming_lead_state_id, leads: payload.mappedNestedData, sourceId: payload.sourceId }
      const { response, data } = yield call(createCSVLeadsRequest, {
        data: { ...payload },
        params: {
          sourceId:
            payload?.sourceId
        }
      });
      yield processAPIResponse({ response, data }, {

        * onSuccess(data) {
          message.success("Leads imported successfully")
          callback && callback(true)

          yield put({
            type: 'fetch',
          })
          yield put({
            type: 'fetchMe',
          })
        }
        , * onError(error) {
          callback && callback(false)

          message.error(error?.error?.message)

        }
      })


    },
    * deleteLeadSource({ payload: { id, cb, intl } }, { call, put }) {

      const { response, data } = yield call(deleteLeadSource, { params: { sourceId: id } });
      yield processAPIResponse({ response, data }, {
        * onSuccess() {
          yield put({
            type: 'fetchMe',
          })
          intl && message.success(intl.formatMessage({ id: "pages.leads.removeLeadSourceSuccess", defaultMessage: 'Lead Source deleted successfully' }))
          cb && cb(data)
        },
        * onError({ error }) {
          debugger
          cb && cb(error)
          message.error(error?.message || intl.formatMessage({ id: "pages.leads.removeLeadSourceFailed", defaultMessage: 'Remove Lead Source failed' }))
        },
      })
    },
    * fetchLeadSource({ payload: { id } }: { payload: { id?: string } }, { call, put, select }) {
      const { response, data } = yield call(getLeadSource, { params: { sourceId: id } });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeadSourceDetail',
            payload: data,
          })
        },
        * onError(error) {

        }
      }, 'data')
    },
    * updateLeadSource({ payload }, { call, put, select }) {
      const callback = payload?.cb

      const { response, data } = yield call(updateCSVLeadSourceRequest, {
        data: { ...payload.allData },
      });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          callback && callback(true)

          yield put({
            type: 'fetchMe',
          })
          yield put({
            type: 'createCSVLeads',
            payload: {
              ...payload, sourceId:
                data?.data?.lead_source?.id
            }
          })
        }
        , * onError({ error }) {
          callback && callback(false)

          message.error(error?.error?.message)

        }
      })


    },
  },

  reducers: {
    saveLeads(state, action) {
      let newList = action.payload
      const isPaged = action?.options?.concat || action?.options?.url
      const leads = state.leads || {}
      // Kanban pagination
      if (action?.options?.group_by || action?.options?.url) {
        if (!isPaged) {
          newList.data.forEach(list => {
            list.data.forEach(lead => {
              leads[lead.id] = lead
            })
          })
          const newGroupedList = newList.data.map(groupedLeads => ({
            ...groupedLeads,
            data: groupedLeads.data.map(lead => lead.id)
          }))

          return {
            ...state,
            leads,
            groupedList: {
              data: newGroupedList
            }
          }
        }
        newList.data.forEach(lead => {
          leads[lead.id] = lead
        })
        return {
          ...state,
          leads,
          groupedList: {
            ...state.groupedList,
            data: state.groupedList.data.map(groupedLead => {
              if (groupedLead.filter_id === action.options.groupId) {
                return {
                  ...groupedLead,
                  ...newList,
                  data: groupedLead.data.concat(newList.data.map(lead => lead.id))
                }
              }
              return groupedLead
            }),
          }
        }

      }
      newList.data.forEach(lead => {
        leads[lead.id] = lead
      })

      // Table pagination
      if (isPaged) {
        newList = {
          ...newList,
          ...state.list,
          ...action.payload,
          data: state.list.data.concat(newList.data.map(lead => lead.id)),
        }
        return {
          ...state,
          leads,
          list: newList
        }
      }

      return {
        ...state,
        leads,
        list: {
          ...newList,
          data: newList.data.map(lead => lead.id)
        },
      };
    },
    saveUnassignedLeads(state, action) {
      return {
        ...state,
        unassignedLeadsList: action.payload,
      };
    },
    saveServiceRequests(state, action) {
      const serviceRequestList = action.payload?.data || []
      const newLeads = {}
      serviceRequestList.forEach(serviceRequest => {
        if (serviceRequest.lead){
          newLeads[serviceRequest.lead.id] = serviceRequest.lead
        }
      })

      return {
        ...state,
        serviceRequests: action.payload,
        leads: {
          ...state.leads,
          ...(newLeads || {})
        }
      };
    },
    saveLeadStatuses(state, action) {
      return {
        ...state,
        statusSettings: action.payload,
      };
    },
    saveLeadDetails(state, action) {
      return {
        ...state,
        details: {
          ...state.details,
          [action.payload.id]: action.payload
        },
      };
    },
    saveTaskList(state, action) {
      return {
        ...state,
        taskList: action.payload,
      };
    },
    modifyFilters(state, action) {
      return {
        ...state,
        filters: merge(state.filters, action.payload),
      };
    },
    setPerformanceChartFilters(state, action) {
      return {
        ...state,
        performanceChartFilters: merge(state.performanceChartFilters, action.payload),
      };
    },
    setPerformanceChartData(state, action) {
      return {
        ...state,
        leadsReportData: action.payload,
      };
    },
    setFunnelChartData(state, action) {
      return {
        ...state,
        funnelReportData: action.payload,
      };
    },
    setRoiFunnelChartData(state, action) {
      return {
        ...state,
        roiFunnelReportData: action.payload,
      };
    },
    setRoiFunnelChartFilters(state, action) {
      return {
        ...state,
        roiFunnelChartFilters: merge(state.roiFunnelChartFilters, action.payload),
      };
    },
    editLeadLocally(state, action) {
      const { leadId } = action.payload
      const mergeItem = (item) => {
        Object.keys(omit(action.payload, ['leadId'])).forEach(key => {
          set(item, key, action.payload[key])
        })
        return item
      }
      const prevLead = state.leads[leadId]
      if (!prevLead) return state

      const newLead = mergeItem(prevLead)
      return {
        ...state,
        leads: {
          ...state.leads,
          [leadId]: newLead
        }
      }
    },
    editLeadDetails(state, action) {
      const { lead } = action.payload
      return {
        ...state,
        leads: {
          ...state.leads,
          [lead.id]: lead
        }
      }
    },
    setChangeLoadingsForKey(state, action) {
      return setChangeLoadingsUtility(state, action)
    },
    modifyViewSettings(state, action) {
      return {
        ...state,
        viewSettings: merge(state.viewSettings, action.payload),
      };
    },
    setViewSettings(state, action) {
      return {
        ...state,
        viewSettings: {
          ...state.viewSettings,
          ...action.payload
        },
      };
    },
    doTask(state, { payload }) {
      return {
        ...state,
        taskList: state.taskList.map(task => task.id === payload.taskId ? {
          ...task,
          done: true
        } : task)
      }
    },
    setForcedAssignmentLeadId(state, { payload }) {
      return {
        ...state,
        forcedAssignmentLeadId: payload.leadId
      }
    },
    setLeadAssignee(state, { payload }) {
      return {
        ...state,
        assignHistory: {
          ...state.assignHistory,
          [payload.leadId]: payload.assignee
        }
      }
    },
    setArchivedLeads(state, { payload }) {
      return {
        ...state,
        archivedLeads: {
          ...state.archivedLeads,
          ...payload
        }
      }
    },
    toggleAssigneeDrawer(state, { payload }) {
      return {
        ...state,
        assignmentDrawerVisible: !state.assignmentDrawerVisible
      };
    },
    toggleLeadDetailsDrawer(state, { payload }) {
      const lead = get(payload, 'lead')
      return {
        ...state,
        leadDetailsDrawer: {
          lead
        }
      };
    },
    setAssignees(state, action) {
      const {
        payload: { assigneeList },
      } = action;

      return {
        ...state,
        assigneeList
      };
    },
    setLeadSources(state, action) {
      const {
        payload: { leadSourceList },
      } = action;

      return {
        ...state,
        leadSourceList
      };
    },
    setServices(state, action) {
      const {
        payload: { serviceList },
      } = action;

      return {
        ...state,
        serviceList
      };
    },
    setLeadStatuses(state, action) {
      const {
        payload: { leadStatusList },
      } = action;

      return {
        ...state,
        leadStatusList
      };
    },
    setArchiveLoading(state, action) {
      const {
        payload,
      } = action;
      return {
        ...state,
        archiveLoading: {
          ...state.archiveLoading,
          ...payload
        }
      };
    },
    saveLeadFields(state, action) {
      return {
        ...state,
        leadFields: action.payload,
      };
    },
    toggleAddLeadsDrawer(state, { payload }) {
      const addLead = { ...state.addLead, drawer: { visible: !state.addLead?.drawer?.visible } }
      return {
        ...state,
        addLead
      }
    },
    saveLeadSourceDetail(state, action) {
      return {
        ...state,
        leadSourceDetail: action.payload,
      };
    },
  }
};
