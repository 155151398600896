import React, {useEffect, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {getLocale, useDispatch, useSelector} from "umi";
import * as LeadsSelectors from "@/selectors/leadSelectors"

import styles from "./styles.less"
import LeadAssigneeOverview from "@/components/Lead/LeadAssigneeOverview";
import {Grid, message, Popover, Space, Spin} from "antd";
import LeadName from "@/components/Lead/LeadName";
import get from "lodash/get";
import {ClockCircleOutlined} from "@ant-design/icons";
import {PrettyHour} from "@/components/PrettyDate";
import LeadEmail from "@/components/Lead/LeadEmail";
import LeadPhone from "@/components/Lead/LeadPhone";

import frLocale from "@fullcalendar/core/locales/fr"
import deLocale from "@fullcalendar/core/locales/de"
import enLocale from "@fullcalendar/core/locales/en-gb"
import nlLocale from "@fullcalendar/core/locales/nl"
import esLocale from "@fullcalendar/core/locales/es"
import {rescheduleServiceRequestRequest} from "@/services/api/lead";


const localeMap = {
  fr: frLocale,
  de: deLocale,
  en: enLocale,
  nl: nlLocale,
  es: esLocale
}
let eventGuid = 0

export function createEventId() {
  return String(eventGuid++)
}

export default function LeadsCalendar({list, request, loading}) {
  const [currentEvents, setCurrentEvents] = useState([])
  const [isTodayVisible, setIsTodayVisible] = useState(true);
  const dispatch = useDispatch()

  const {useBreakpoint} = Grid;
  const screens = useBreakpoint()

  const language = getLocale()?.split('-')[0];
  const locale = get(localeMap, language, enLocale)

  const allLeadsData = useSelector(LeadsSelectors.selectAllLeadsData)
  const appointments = list?.data || []


  useEffect(() => {
    request()
  }, []);

  const getDefaultView = () => screens.sm ? 'timeGridWeek' : 'timeGridDay'

  const getEvents = () => (appointments || []).filter(appointment => appointment.utc_start_time).map(appointment => {
    const leadId = appointment?.lead?.id
    if (!leadId) return null
    const lead = allLeadsData[leadId] || {}
    const start = new Date(appointment.utc_start_time);
    const end = new Date(appointment.utc_end_time); // Add 2 hours to the start time

    return {
      id: appointment.id,
      start: start.toISOString(),
      end: end.toISOString(),
      backgroundColor: lead.lead_state?.color || '#ffffff', // Fallback color
      extendedProps: {
        lead,
        appointment
      }
    };
  }).filter(appointment => appointment)


  const handleEventResize = (info) => {
    const maxDurationMinutes = 60; // Set maximum allowed duration in minutes
    const duration = (info.event.end - info.event.start) / (1000 * 60); // Calculate duration in minutes

    if (duration > maxDurationMinutes) {
      info.revert(); // Revert the change if it exceeds the max duration
    }
  }

  const events = getEvents()
  function handleDateSelect(selectInfo) {
    alert("Creating manual appointment coming soon!")
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect()
  }


  function handleEventClick(clickInfo) {
    const lead = get(clickInfo, 'event.extendedProps.lead')
    if (!lead) return
    dispatch({
      type: "leads/toggleLeadDetailsDrawer",
      payload: {
        lead
      }
    })
  }
  const handleDatesSet = (info) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date

    if (info.start <= today && today <= info.end) {
      setIsTodayVisible(false);
    } else {
      setIsTodayVisible(true);
    }
  };

  function handleEvents(events) {
    setCurrentEvents(events)
  }
  function formatToISOStringWithOffset(date) {
    // Get the local timezone offset in minutes
    const offset = date.getTimezoneOffset();
    const offsetHours = Math.abs(Math.floor(offset / 60)).toString().padStart(2, '0');
    const offsetMinutes = Math.abs(offset % 60).toString().padStart(2, '0');
    const offsetSign = offset > 0 ? '-' : '+';

    // Format the date and time components
    const formattedDate = date.toISOString().split('T')[0];
    const formattedTime = date.toTimeString().split(' ')[0];

    return `${formattedDate}T${formattedTime}${offsetSign}${offsetHours}:${offsetMinutes}`;
  }
  const handleEventDrop = (info) => {
    const {event} = info
    rescheduleServiceRequestRequest({
      params: {
        serviceRequestId: event.id
      },
      data: {
        start: formatToISOStringWithOffset(event.start),
        end: formatToISOStringWithOffset(event.end)
      }
    }).then(() => {
      message.success("Reschedules successfully")
    })
  }
  const eventAllow = (dropInfo, draggedEvent) => {
    const currentDate = new Date();
    const newDate = dropInfo.start;
    // Check if the new date is in the past
    if (newDate < currentDate) {
      return false; // Prevents rescheduling
    }
    return true; // Allows rescheduling
  }

  // Custom render function to style events
  const renderEventContent = (eventInfo) => {
    const event = eventInfo.event
    const lead = event?.extendedProps?.lead
    if (!lead) return null
    return (
      <Popover placement={"rightTop"} content={<div>
        <Space direction={"vertical"}>
          <LeadName lead={lead}/>
          <LeadEmail lead={lead} readonly/>
          <LeadPhone lead={lead} readonly/>
          <LeadAssigneeOverview leadContextMenuProps={{disableAssignAction: true}}
                                lead={lead}
                                showAssigneeName
          />
        </Space>
      </div>}>
        <div className={styles.calendarEventContainer} style={{
          backgroundColor: lead?.lead_state?.color
        }}>
          <div className={styles.eventName}>
            <LeadName lead={lead} hideAvatar readonly/>
          </div>
          <div className={styles.eventFooter}>
            <Space>
              <Space>
                <ClockCircleOutlined/>
                <PrettyHour date={event.start}/>
              </Space>
              {lead && lead.assignee && <LeadAssigneeOverview leadContextMenuProps={{disableAssignAction: true}}
                                                              avatarClassName={styles.eventAvatar} lead={lead}/>}
            </Space>
          </div>
        </div>
      </Popover>
    );
  };
  return (
    <Spin spinning={loading}>
      <div className={styles.fullCalendarContainer}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: isTodayVisible ? 'today' : '',
            center: 'prev title next',
            right: ''
            // right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          initialView={getDefaultView()}
          key={getDefaultView()}
          editable={true}
          eventResizableFromStart={false}
          eventDurationEditable={false}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          firstDay={1}
          allDaySlot={false}
          events={events}
          eventAllow={eventAllow}
          eventContent={renderEventContent}
          eventClassNames={(arg) => [styles.fcEventContainer]}
          eventResize={handleEventResize}
          datesSet={handleDatesSet}
          select={handleDateSelect}
          eventClick={handleEventClick}
          height={"100%"}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // Ensures 24-hour format
          }}
          locale={locale}
          eventDrop={handleEventDrop}
          // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
          eventAdd={function(){}}
          eventChange={function(){}}
          eventRemove={function(){}}
          */
        />
      </div>
    </Spin>
  )
}

function renderAppointment(eventInfo) {

  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}
