import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage as F, useDispatch, useIntl, useModel, useSelector} from 'umi';
import ProForm, {ProFormText, ProFormTextArea} from '@ant-design/pro-form';
import {Button, Col, Form, message, Row, Space} from 'antd';
import isEmpty from 'lodash/isEmpty';

import styles from './index.less';
import {LeadModel} from '@/typings/models/Lead';
import LeadStatusFilters from "@/components/Filters/LeadStatusFilters";
import LeadAssigneeDropdownFilters from "@/components/Filters/LeadAssigneeDropdownFilters";
import * as LeadSelectors from "@/selectors/leadSelectors";
import BranchDropdownFilters from "@/components/Filters/BranchDropdownFilters";
import pick from "lodash/pick";
import omit from "lodash/omit";
import { LEAD_SOURCE_TYPE_MANUAL } from '../constants';

const CUSTOMER_FIELDS = ['firstname', 'lastname', 'email', 'phone']
const AddManualLead: React.FC = () => {
  const [lead] = useState<LeadModel>({});
  const {initialState, setInitialState} = useModel('@@initialState');
  const loggingUser = initialState?.currentUser?.User;
  const focusInputRef = useRef(null);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const leadStatuses = useSelector(LeadSelectors.selectStatusSettings)
  const addLeadDrawer = useSelector(LeadSelectors.selectAddLeadDrawer)
  const addLeadLoading = useSelector((state: DefaultRootState) => state.loading.effects['leads/create'])

  const defaultStatus = addLeadDrawer?.statusId || leadStatuses[0]?.id
  const open = !isEmpty(addLeadDrawer)
  const { leadSourceList } = useSelector((state: DefaultRootState) => ({
    leadSourceList: state.journey.list,
  }));
  function toggle() {
    dispatch({
      type: 'leads/toggleAddLeadDrawer',
    });
  }

  const fetchAssignees = (text = '') => {
    dispatch({
      type: 'leads/fetchAssignees',
    });
  };

  useEffect(() => {
    if (open) {
      if (focusInputRef.current)
        focusInputRef.current.focus();

      form.setFieldsValue({
        // assignee: isEmpty(loggingUser) ? undefined : loggingUser.id,
        lead_state_id: defaultStatus
      });
      fetchAssignees();
      return
    }
    form.resetFields()

  }, [open]);

  function onCreateLead() {
    form.validateFields().then(() => {
      const formData = form.getFieldsValue()
      const customer = pick(formData, CUSTOMER_FIELDS)
      const rest = omit(formData, CUSTOMER_FIELDS)
      const manualLeadSource = leadSourceList?.filter((source) => source.type === LEAD_SOURCE_TYPE_MANUAL)?.[0]
      if(!manualLeadSource){
        message.error('Manual lead source not found');
        return
      }
      dispatch({
        type: 'leads/create',
        payload: {
          sourceId: manualLeadSource
            ?.hashId,
          intl,
          data: {
            customer,
            ...rest,
          },
        },
      });
    })
  }

  const intl = useIntl();

  return (
    <div className={styles.drawerContainer}>
      <div>
        <ProForm form={form} submitter={false}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label={<F id={'pages.leadsources.manual.chooseStatus'} defaultMessage={'Choose status'} />} name="lead_state_id">
                <LeadStatusFilters filterMode={'select'} selectProps={{ allowClear: false }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label={<F id={'pages.leadsources.assignedTo'} defaultMessage={'Assign To'} />} name="assignee_id">
                <LeadAssigneeDropdownFilters filterMode={'select'} />
              </Form.Item>
            </Col>
          </Row>

          <ProFormText
            label={intl.formatMessage({
              id: 'pages.customers.firstName',
              defaultMessage: 'First Name',
            })}
            required
            fieldProps={{
              autoFocus: true,
              ref: focusInputRef,
            }}
            name={'firstname'}
            rules={[
              {
                required: true,
                type: 'string',
              },
            ]}
          />
          <ProFormText
            name={'lastname'}
            label={intl.formatMessage({
              id: 'pages.customers.lastName',
              defaultMessage: 'Last Name',
            })}
            required
            rules={[
              {
                required: true,
                type: 'string',
              },
            ]}
          />

          <ProFormText
            name="email"
            label={intl.formatMessage({
              id: 'pages.resource.email',
              defaultMessage: 'Email',
            })}
          />
          <ProFormText
            name="phone"
            rules={[
              {
                /*
                  ^[+]?[0-9]{1,4}?: Matches an optional + followed by 1 to 4 digits (country code).
[-.\s]?: Matches an optional separator (dash, dot, or space).
[(]?[0-9]{1,3}?[)]?: Matches an optional area code enclosed in parentheses.
[-.\s]?[0-9]{1,4}: Matches 1 to 4 digits with an optional separator.
[-.\s]?[0-9]{1,4}: Matches another set of 1 to 4 digits with an optional separator.
[-.\s]?[0-9]{1,9}$: Matches the final set of 1 to 9 digits with an optional separator.
                  */
                pattern:
                  /^[+]?[0-9]{1,4}?[-.\s]?[(]?[0-9]{1,3}?[)]?[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{1,9}$/,
                message: intl.formatMessage({
                  id: 'pages.users.phone.invalidFormat',
                  defaultMessage: 'Please enter a valid phone number.',
                }),
              },
            ]}
            label={intl.formatMessage({
              id: 'pages.users.phone',
              defaultMessage: 'Phone',
            })}
          />
          <Form.Item
            label={intl.formatMessage({ id: 'pages.leads.branch', defaultMessage: 'Branch' })}
            name="branch_id"
          >
            <BranchDropdownFilters filterMode={'select'} />
          </Form.Item>
          <ProFormTextArea
            name="note"
            label={intl.formatMessage({
              id: 'pages.customer.notes',
              defaultMessage: 'Notes',
            })}
          ></ProFormTextArea>
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Button
                className={'button-secondary button-bordered'}
                size={'large'}
                type={'primary'}
                onClick={toggle}
              >
                <F id={'app.settings.close'} defaultMessage={'Close'} />
              </Button>

              <Button
                size={'large'}
                onClick={onCreateLead}
                loading={addLeadLoading}
                type={'primary'}
              >
                <F id={'pages.leads.addLead'} defaultMessage={'Add Lead'} />
              </Button>
            </Space>
          </div>
        </ProForm>
      </div>
    </div>
  );
};

export default AddManualLead;
