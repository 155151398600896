import React, {useState} from "react";
import {Avatar, Form, Popover, Space} from "antd";
import isEmpty from "lodash/isEmpty";

import {LeadModel} from "@/typings/models/Lead";

import styles from "./index.less"
import {EditOutlined} from "@ant-design/icons";
import ProForm, {ProFormText} from "@ant-design/pro-form";
import {useDispatch, useIntl} from "umi";


const LeadNameEditForm = ({lead, hidePopover}: { lead: LeadModel, hidePopover: () => void }) => {
  const [form] = Form.useForm();
  const intl = useIntl()
  const dispatch = useDispatch()
  const handleSubmit = values => {
    dispatch({
      type: 'leads/edit',
      payload: {
        leadId: lead.id,
        'customer.firstname': values.firstname,
        'customer.lastname': values.lastname,
      }
    })
    hidePopover()
  }
  return <div>
    <ProForm
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        ...lead.customer
      }}
      submitter={{
        resetButtonProps: {
          style: {
            display: 'none'
          }
        },
        submitButtonProps: {
          block: true
        }
      }}
    >
      <ProFormText
        label={intl.formatMessage({
          id: 'pages.customers.firstName',
          defaultMessage: 'First Name',
        })}
        required
        fieldProps={{
          autoFocus: true,
        }}
        name={"firstname"}
        rules={[{
          required: true,
          type: 'string'
        }]}
      />
      <ProFormText
        name={"lastname"}
        label={intl.formatMessage({
          id: 'pages.customers.lastName',
          defaultMessage: 'Last Name',
        })}
        required
        rules={[{
          required: true,
          type: 'string'
        }]}
      />
    </ProForm>
  </div>
}

const LeadName = ({lead, readonly, hideAvatar, justAvatar}: { lead: LeadModel, readonly?: boolean , hideAvatar?: boolean  , justAvatar?: boolean }) => {
  const [editPopoverOpen, setEditPopoverOpen] = useState(false)
  const {customer} = lead
  if (isEmpty(customer) || (isEmpty(customer.firstname) && isEmpty(customer.lastname))) return null
  const name = `${customer.firstname} ${customer.lastname}`
  const avatar = <Avatar size={"small"} className={styles.leadNameAvatar}>{name[0]}</Avatar>
  if (justAvatar) return avatar

  return <div className={styles.editableTextWrapper}>
    <Space>
      {!hideAvatar && <Avatar size={"small"} className={styles.leadNameAvatar}>{name[0]}</Avatar>}
      {name}
      {!readonly && <Popover destroyTooltipOnHide trigger={['click']} content={<LeadNameEditForm lead={lead} hidePopover={() => setEditPopoverOpen(false)}/>} open={editPopoverOpen} onOpenChange={setEditPopoverOpen}>
        <span className={styles.editIcon}><EditOutlined/></span>
      </Popover>}
    </Space>
  </div>
}

export default LeadName
