import { useEffect, useState } from "react";
import {
  Button,
  Form,
  message,
  Steps,
  Row,
  Divider
} from "antd";
import { useDispatch, useSelector, useIntl, FormattedMessage as F } from "umi";
import { parse } from "csv-parse/browser/esm";
import * as LeadSelectors from "@/selectors/leadSelectors";
import { buildDataBasedOnMappings, buildNestedDataBasedOnMappings, detectDelimiter } from "./utils";
import PreviewParsedDataTableSegment from "./PreviewParsedDataTableSegment";
import MapColumnsSegment from "./MapColumnsSegment";
import CSVLeadSourceImportSegment from "./CSVLeadSourceImportSegment";
import { LEAD_SOURCE_TYPE_CSV } from "../constants";
import isEmpty from 'lodash/isEmpty';
const { Step } = Steps;

const NewLeadSource = ({ open, onClose, config, isNew }) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [loadingState, setLoadingState] = useState('');
  const [parsedData, setParsedData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [mapping, setMapping] = useState();
  const [mappingForm] = Form.useForm();
  const [mappedData, setMappedData] = useState([]);
  const [mappedNestedData, setMappedNestedData] = useState([]);
  const { systemFields, leadName } = useSelector((state: DefaultRootState) => ({
    systemFields: LeadSelectors.selectLeadFields(state),
    leadName: state.journey?.selectedJourney?.name,
  }));
  const [customFields, setCustomFields] = useState([]);
  const [stepLoading, setStepLoading] = useState(false);
  const [allData, setAllData] = useState(isNew ? {} : config);
  const [delimiter, setDelimiter] = useState("");
  const [file, setFile] = useState(null);

  
  useEffect(() => {
    dispatch({
      type: 'leads/fetchLeadFields',
    });
  }, []);
  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  useEffect(() => {
    if(!isEmpty(file))
    handleFileUpload(file);


  }, [delimiter]);
  const intl = useIntl();

  useEffect(() => {
    if (isEmpty(config?.configuration?.parsing)) {
      const separator = config?.configuration?.parsing?.separator?.replace(/['"]/g, '');
      setDelimiter(separator);
    }
    if (!isEmpty(config)) {
      setAllData(config);

    }

  }, [config]);

  const handleFileUpload = (file) => {
    setFile(file);
    setIsUploading(true);
    setLoadingState('Parsing CSV...');

    const reader = new FileReader();
    reader.onload = () => {
      const csvData = reader.result;
      const detectedDelimiter = detectDelimiter(csvData);
      setDelimiter(detectedDelimiter);

      parse(
        csvData,
        {
          columns: true,
          skip_empty_lines: true,
          trim: true,
          delimiter: detectedDelimiter,
        },
        (err, records) => {
          if (err) {
            message.error(intl.formatMessage({defaultMessage: 'Error parsing CSV file', id: 'pages.leadsource.csv.parseError'}));
            console.error(err);
            setLoadingState('');
            setIsUploading(false);
          } else {
            setParsedData(records);
            if (records.length > 0) {
              setCsvHeaders(Object.keys(records[0]));
              setLoadingState(intl.formatMessage({id: 'pages.leadsources.csv.parseSuccess', defaultMessage: 'CSV Parsed Successfully!'}));
              setTimeout(() => {
                setLoadingState('');
                setIsUploading(false);
                setCurrentStep(1); // Move to Mapping Step
              }, 1000);
            }
          }
        },
      );
    };
    reader.onerror = () => {
      message.error(intl.formatMessage({id: 'pages.leadsources.csv.errorReadingFile'}));
      setLoadingState('');
      setIsUploading(false);
    };
    reader.readAsText(file);

    return false;
  };

  const handleMappingSubmit = async (values) => {
    
    setStepLoading(true);
    setMapping(values);
    
    const newMappedData = await buildDataBasedOnMappings(values, customFields, parsedData);
    const newMappedNestedData = await buildNestedDataBasedOnMappings(
      values,
      customFields,
      parsedData,
    );
    setMappedData(newMappedData);
    setMappedNestedData(newMappedNestedData);
    setLoadingState(intl.formatMessage({id: 'pages.leadsources.csv.mappingSuccessful', defaultMessage: 'Data Mapped Successfully!'}));
    setTimeout(() => {
      setLoadingState('');
      setCurrentStep(2);
      setStepLoading(false);
    }, 1000);
  };
  const transformObject = (inputObject) => {
    const resultArray = Object.keys(inputObject)
      .filter((key) => inputObject[key] && inputObject[key]?.length)
      .map((key) => {
        return {
          target_field: key,
          sources: inputObject[key],
        };
      });
    return resultArray;
  };
  const sendDataToServer = async () => {
    if (isNew && !allData['id']) {
      setStepLoading(true);
      allData['type'] = LEAD_SOURCE_TYPE_CSV;
      allData['configuration'] = {
        parsing: {
          separator: delimiter,
        },
        mappings: transformObject(mapping),
      };
      dispatch({
        type: 'leads/createCSVLeadSource',
        payload: {
          allData,
          mappedNestedData,
          cb: (succeeded) => {
            setStepLoading(false);
            if (succeeded) {
              onClose();
            }
          },
        },
      });
    } else {
      setStepLoading(true);
      allData['type'] = LEAD_SOURCE_TYPE_CSV;
      allData['configuration'] = {
        parsing: {
          separator: ',',
        },
        mappings: transformObject(mapping),
      };
      dispatch({
        type: 'leads/updateLeadSource',
        cb: (succeeded) => {
          setStepLoading(false);
          if (succeeded) {
            onClose();
          }
        },
        payload: {
          allData,
          mappedNestedData,
          cb: (succeeded) => {
            setStepLoading(false);
            if (succeeded) {
              onClose();
            }
          },
        },
      });
    }
  };

  // Add custom field
  const addCustomField = () => {
    const newField = { key: Date.now(), name: '', csvHeaders: [] };
    setCustomFields([...customFields, newField]);
  };

  const handleCustomFieldNameChange = (key, value) => {
    setCustomFields(
      customFields.map((field) => (field.key === key ? { ...field, name: value } : field)),
    );
  };

  const handleCustomFieldCsvHeadersChange = (key, value) => {
    setCustomFields(
      customFields.map((field) => (field.key === key ? { ...field, csvHeaders: value } : field)),
    );
  };

  const previewColumns = [
    ...(systemFields?.default || [])?.map((field) => ({
      title: field.label,
      dataIndex: field.key,
      key: field.key,
    })),
    ...(systemFields?.meta|| [])?.map((field) => ({
      title: field.name ?? field.label ?? field.key,
      dataIndex: ['meta', field.name ?? field.key],
      key: `custom-${field.key}`,
    })),
  ];

  // Steps Configuration
  const steps = [
    {
      title: intl.formatMessage({id: 'pages.leadsources.csv.upload'}),
      content: (
        <CSVLeadSourceImportSegment
          config={config}
          leadName={leadName}
          isNew={isNew}
          handleFileUpload={handleFileUpload}
          loadingState={loadingState}
          isUploading={isUploading}
          onValuesChange={(changedValues, allValues) => {
            setAllData({ ...allData, ...allValues });
          }}
        />
      ),
    },
    {
      title: intl.formatMessage({id: 'pages.leadsources.csv.map'}),
      content: (
        <MapColumnsSegment
          mappingForm={mappingForm}
          handleMappingSubmit={handleMappingSubmit}
          systemFields={systemFields}
          config={config}
          isNew={isNew}
          csvHeaders={csvHeaders}
          customFields={customFields}
          handleCustomFieldNameChange={handleCustomFieldNameChange}
          handleCustomFieldCsvHeadersChange={handleCustomFieldCsvHeadersChange}
          addCustomField={addCustomField}
          nextStep={nextStep}
          prevStep={prevStep}
          onClose={onClose}
          setDelimiter={setDelimiter}
          delimiter={delimiter}
          file={file}

        />
      ),
    },
    {
      title: intl.formatMessage({id: 'pages.leadsources.csv.preview'}),
      content: (
        <PreviewParsedDataTableSegment
          mappedData={mappedData}
          previewColumns={previewColumns}
          sendDataToServer={sendDataToServer}
          stepLoading={stepLoading}
          prevStep={prevStep}
          onClose={onClose}
          setDelimiter={setDelimiter}
          file={file}

        />
      ),
    },
  ];

  return (
    <>
      <div>
        <Row justify="center" style={{ marginBottom: 24 }}>
          <Steps current={currentStep} style={{ width: '80%' }}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Row>
        <Divider />

        <div>{steps[currentStep].content}</div>
        {currentStep === 0 && (
          <div style={{ marginTop: 24 }}>
            <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
              <F id='pages.common.cancel' defaultMessage='Cancel' />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default NewLeadSource;